import {createReducer, on} from '@ngrx/store';
import {AppConfig} from 'src/app/config';
import {
  changePaymentGateway,
  changeSelectedLanguage,
  configApiFailure,
  configApiSuccess,
  dataUpdationFromSocket,
  getAllReceiptTemplatesSuccess,
  getAllTaxDetailsSuccess,
  isAllowedGoHome,
  loadCounterDataSuccess,
  resetSessionTimer,
  restartSlotTimer,
  setPrinterOffline,
  setPrinterStatus,
  setprintPreviewImg,
  showBlockPopUp,
  stopPrinterPolling,
  submitRatingFeedbackSuccess,
  switchDinningStatus,
  switchTheme,
  updatePrinterStatus,
} from './config.action';
import {AvailableDine, KioskSettings,} from '../../models/kioskUiSettings.model';
import {environment} from 'src/environments/environment';
import {loadPopularProductsFailure} from '../products/product.action';
import {IStatus} from 'src/app/constants';
// import { IStatus } from 'src/app/constants';

export type ConfigReducer = Record<string, KioskSettings>;

export const initialState: ConfigReducer = {
  default: {
    kioskUISettings: {
      menu: {
        initialState: {
          status: 'allOpen',
          accordionState: 'multiOpen',
        },
      },
      landingPage: {
        landingPage: 'all',
      },
      timer: {
        payment: {
          value: 180,
          unit: 'second',
        },
        popup: {
          value: 20,
          unit: 'second',
        },
        global: {
          value: 20,
          unit: 'second',
        },
      },
      dineType: {
        dineIn: true,
        dineOut: true,
      },
      environment: 'dev',
      theme: {
        default: 'dark',
        switchThemes: true,
        colors: {
          light: {
            primary: '#5da67f',
            secondary: '#a903fc',
            accent: '#fc0341',
            background: '#e7fc03',
          },
          dark: {
            primary: '#000',
            secondary: '#e7a200',
            accent: '#c89d28',
            background: '#3c2015',
          },
        },
        isEditable: false,
      },
      miscellaneous: {
        autoCartOpen: false,
        wallpaper: false,
        showInstructions: false,
        printerPollingEnabled: true,
        maxQuantityAllowed: 100,
        discardOrderOnCancellingPayment: false,
        isRatingSubmitted: false,
        showPopular: false,
        showAddons: false,
        offlineTaxCalculation: false,
        browserPrinting: false,
        kioskDisabled: false,
      },
    },
    currentTheme: 'dark',
    dineStatusSwitchingEnabled: false,
    language: {
      enabled: true,
      name: 'hindi',
      alternateLanguage1: {
        label: 'ಕನ್ನಡ',
        id: 'kn',
        order: 6,
        default: false,
      },
      defaultLanguage: {
        label: 'English',
        id: 'en',
        order: 1,
        default: true,
      },
      systemLanguages: [
        {
          label: 'English',
          id: 'en',
          order: 1,
          default: true,
        },
        {
          label: 'ಕನ್ನಡ',
          id: 'kn',
          order: 6,
          default: false,
        },
      ],
    },
    isRegionalLanguageSelected: false,
    isMultiLanguageEnabled: false,
    currentSessionTimer: {
      global: 20,
    },
    defaultSet: false,
    availableDining: new Array<AvailableDine>(),
    blockUiToast: {
      showButton: false,
      msg: '',
      info: '',
      header: '',
    },
    isPrinterPollingEnabled: false,
    printPreviewUrl: '',
    autoPrint: {
      invoice: true,
      token: true,
    },
    updatedDataTrack: {
      accountId: '',
      store: '',
      updatedData: [],
    },
    stopSlotTimer: 0,
    slots: {
      window: [],
      enabled: false,
    },
    isDining: true,
    inventoryManagement: {
      stockCheck: false,
      minThresholdNotifier: 0,
    },
    payment: {
      gateway: '',
      paymentModes: [],
      kioskFront: {
        inAppPayment: true,
        sendPaymentLink: false,
        payInStore: true,
        tryDifferentMethods: true,
      },
    },
    parcelCharge: null,
    kAgent: null,
    printConfig: null,
    taxDetails: null,
    receiptTemplates: null,
    printerStatus: null,
    printerData: {
      kAgent: null,
      printConfig: null,
      counterData: null,
      counterPrinting:null,
      isPrinterPolingEnabled: false
    },
    isGoHomeAllowed: true,
    wallpaperConfig: {
      showButtons : false,
      showLogo: false,
      showPointer: false
    },
    productPricing: {
      roundingEnabled: false,
      taxIncluded: false
    }
  },
};

export const ConfigReducer = createReducer(
  initialState,
  on(configApiSuccess, (state, {
    data,
    key
  }) => {
    if (data?.language?.defaultLanguage?.id) {
      sessionStorage.setItem('lang_label', data?.language?.defaultLanguage?.id);
    } else {
      sessionStorage.setItem('lang_label', 'en');
    }
    
    const availableDine = [];
    
    if (data?.kioskUISettings?.dineType?.dineIn) {
      const obj = {
        labelKey: '',
        value: '',
      };
      obj.labelKey = 'kiosk.checkoutPage.dineIn';
      obj.value = 'dineIn';
      availableDine.push(obj);
    }
    if (data?.kioskUISettings?.dineType?.dineOut) {
      const obj = {
        labelKey: '',
        value: '',
      };
      obj.labelKey = 'kiosk.checkoutPage.dineOut';
      availableDine.push(obj);
      obj.value = 'dineOut';
    }
    
    if (
      !data.kioskUISettings?.miscellaneous?.showPopular &&
      data?.kioskUISettings?.landingPage?.['landingPage'] ===
      AppConfig?.mlProductFilterName
    ) {
      data.kioskUISettings.landingPage['landingPage'] = 'categoryMenu';
    }
    const toast = {
      msg: '',
      header: '',
      info: '',
      showButton: false,
    };
    if(data?.kioskUISettings?.miscellaneous?.kioskDisabled){
        toast.showButton = false;
        toast.info = 'kiosk.paymentSuccessScreen.notifyStoreManager';
        toast.msg = 'kiosk.alertBox.kioskOutOfServiceMsg';
        toast.header = 'kiosk.alertBox.kioskOutOfService';
    }
    
    return {
      ...state,
      [key]: {
        ...state[key],
        parcelCharge: data?.['parcelCharge'],
        kAgent: data?.['kAgent'],
        printConfig: data?.['printConfig'],
        printerData: { ...state[key]?.printerData,
          kAgent: data?.['kAgent'],
          printConfig: data?.['printConfig'],
          counterData: state?.[key]?.printerData?.counterData,
          counterPrinting: sessionStorage.getItem('counterPrinting') !== undefined && sessionStorage.getItem('counterPrinting') != null ? JSON.parse(sessionStorage.getItem('counterPrinting')) : {},
          isPrinterPolingEnabled:
            typeof data?.kioskUISettings?.miscellaneous?.printerPollingEnabled ===
            'boolean'
              ? data?.kioskUISettings?.miscellaneous?.printerPollingEnabled
              : environment.pollPrinterStatusApi,
        },
        kioskUISettings: data?.kioskUISettings ?? state?.[key]?.kioskUISettings,
        language: data?.language ?? state?.[key]?.language,
        isMultiLanguageEnabled: data?.language?.systemLanguages?.length > 0,
        isRegionalLanguageSelected: data?.language
          ? data?.language?.defaultLanguage?.id !== 'en'
          : false,
        dineStatusSwitchingEnabled: data?.kioskUISettings?.dineType
          ? data?.kioskUISettings?.dineType?.dineIn &&
          data?.kioskUISettings?.dineType?.dineOut
          : false,
        defaultSet: true,
        availableDining: availableDine,
        currentTheme:
          data?.kioskUISettings?.theme?.default ?? state?.[key]?.currentTheme,
        slots: data?.slots,
        autoPrint: data?.autoPrint ?? state?.[key]?.autoPrint,
        inventoryManagement:
          data?.inventoryManagement ?? state?.[key]?.inventoryManagement,
        payment: data?.payment,
        isPrinterPolingEnabled:
          typeof data?.kioskUISettings?.miscellaneous?.printerPollingEnabled ===
          'boolean'
            ? data?.kioskUISettings?.miscellaneous?.printerPollingEnabled
            : environment.pollPrinterStatusApi,
        wallpaperConfig: data?.wallpaperConfig,
        isGoHomeAllowed: true,
        productPricing: data?.productPricing,
        blockUiToast: toast
      },
    };
  }),
  
  on(configApiFailure, (state, {key}) => {
    return {
      ...state,
      [key]: {
        ...state[key],
        isPrinterPollingEnabled: environment.pollPrinterStatusApi,
      },
    };
  }),
  
  on(loadPopularProductsFailure, (state, {store: key}) => {
    return {
      ...state,
      [key]: {
        ...state[key],
        kioskUISettings: {
          ...state[key]?.kioskUISettings,
          miscellaneous: {
            ...state[key]?.kioskUISettings?.miscellaneous,
            showPopular: state[key]?.kioskUISettings?.miscellaneous?.showPopular,
          },
        },
      },
    };
  }),
  
  on(
    changeSelectedLanguage,
    (state, {
      isRegionalLanguageSelected,
      defaultSet,
      key
    }) => {
      return {
        ...state,
        [key]: {
          ...state[key],
          isRegionalLanguageSelected: !defaultSet
            ? isRegionalLanguageSelected
            : state?.[key]?.language?.defaultLanguage?.id === 'en'
              ? false
              : true,
        },
      };
    }
  ),
  on(resetSessionTimer, (state, {
    timerType,
    key
  }) => {
    return {
      ...state,
      [key]: {
        ...state[key],
        currentSessionTimer: {
          ...state[key]?.currentSessionTimer,
          [timerType]: state?.[key]?.kioskUISettings?.timer?.[timerType]?.value,
        },
      },
    };
  }),
  
  on(switchTheme, (state, {
    changeThemeTo,
    key
  }) => {
    return {
      ...state,
      [key]: {
        ...state[key],
        currentTheme: changeThemeTo,
      },
    };
  }),
  
  on(showBlockPopUp, (state, {
    visible,
    key
  }) => {
    const toast = {
      ...state?.blockUiToast,
      header: '',
      info: '',
      msg: '',
      showButton: false,
    };
    
    if (visible) {
      toast.header = 'kiosk.paymentSuccessScreen.slotUpdated';
      toast.info = 'kiosk.paymentSuccessScreen.refreshData';
      toast.msg = '';
      toast.showButton = true;
    }
    
    return {
      ...state,
      [key]: {
        ...state[key],
        blockUiToast: toast,
      },
    };
  }),
  on(updatePrinterStatus, (state, {
    printerStatusMsg,
    key
  }) => {
    const status = printerStatusMsg ? null : state[key]?.printerStatus?.status;
    if(state?.[key]?.blockUiToast?.header === 'kiosk.alertBox.kioskOutOfService'){
      return {
        ...state,
        [key]: {
          ...state[key],
        },
      };;
    }
    const toast = {
      ...state[key]?.blockUiToast,
      msg: '',
      header: '',
      info: '',
      showButton: false,
    };
    
    if (typeof status === 'string') {
      if (status !== 'online') {
        toast.showButton = false;
        toast.info = 'kiosk.paymentSuccessScreen.notifyStoreManager';
        toast.msg = state[key].printerStatus.status;
        toast.header = 'kiosk.paymentSuccessScreen.printerOutService';
      }
    } else {
      if (typeof status === 'number') {
        toast.msg = 'The printer is not connected properly with device';
        toast.header = 'kiosk.paymentSuccessScreen.printerOutService';
        toast.info = 'kiosk.paymentSuccessScreen.notifyStoreManager';
        toast.showButton = false;
      }
    }
    return {
      ...state,
      [key]: {
        ...state[key],
        blockUiToast: toast,
      },
    };
  }),
  
  on(stopPrinterPolling, (state, {
    isPrinterPollingEnabled,
    key
  }) => {
    return {
      ...state,
      [key]: {
        ...state[key],
        isPrinterPollingEnabled,
      },
    };
  }),
  
  on(setprintPreviewImg, (state, {
    imgUrl,
    key
  }) => {
    return {
      ...state,
      [key]: {
        ...state[key],
        printPreviewUrl: imgUrl,
      },
    };
  }),
  
  on(
    dataUpdationFromSocket,
    (state, {
      store,
      accountId,
      updatedData,
      storeIds
    }) => {
      return {
        ...state,
        [sessionStorage.getItem('location')
          ? sessionStorage.getItem('location')
          : 'default']: {
          ...state[sessionStorage.getItem('location') ?? 'default'],
          updatedDataTrack: {
            store,
            accountId,
            storeIds,
            updatedData: [
              ...(state[sessionStorage.getItem('location')]?.updatedDataTrack
                ?.updatedData ?? []),
              ...updatedData,
            ],
          },
        },
      };
    }
  ),
  
  on(changePaymentGateway, (state, {
    key,
    gateway
  }) => {
    return {
      ...state,
      [key]: {
        ...state?.[key],
        payment: {
          ...state?.[key]?.payment,
          gateway,
        },
      },
    };
  }),
  
  on(restartSlotTimer, (state) => {
    return {
      ...state,
      [sessionStorage.getItem('location')]: {
        ...state[sessionStorage.getItem('location')],
        stopSlotTimer: Math.random(),
      },
    };
  }),
  
  on(switchDinningStatus, (state, {
    isDining,
    resetToDefault,
    key
  }) => {
    const storeKey = key ? key : sessionStorage.getItem('location');
    if (resetToDefault) {
      if (state?.[storeKey]?.availableDining?.length > 1) {
        return {
          ...state,
          [storeKey]: {
            ...state[storeKey],
            isDining,
          },
        };
      } else {
        return {
          ...state,
          [storeKey]: {
            ...state[storeKey],
          },
        };
      }
    } else {
      return {
        ...state,
        [storeKey]: {
          ...state[storeKey],
          isDining,
        },
      };
    }
  }),
  on(submitRatingFeedbackSuccess, (state, {isRatingSubmitted}) => {
    return {
      ...state,
      [sessionStorage.getItem('location')]: {
        ...state[sessionStorage.getItem('location')],
        kioskUISettings: {
          ...state?.[sessionStorage.getItem('location')]?.kioskUISettings,
          miscellaneous: {
            ...state?.[sessionStorage.getItem('location')]?.kioskUISettings
              ?.miscellaneous,
            isRatingSubmitted: isRatingSubmitted,
          },
        },
      },
    };
  }),
  
  on(getAllTaxDetailsSuccess, (state, {
    taxes,
    storeId
  }) => {
    return {
      ...state,
      [storeId]: {
        ...state[storeId],
        taxDetails: taxes,
      },
    };
  }),
  
  on(getAllReceiptTemplatesSuccess, (state, {
    templates,
    storeId
  }) => {
    const temp = {
      ...templates,
      templates: [...templates?.templates],
      assetsInfo: [...templates?.assetsInfo]
    };
    temp?.assetsInfo?.forEach((_, i) => {
      const isPrinterPolingEnabled = state[storeId]?.kioskUISettings?.miscellaneous?.printerPollingEnabled;
      const ObjKey: string[] = Object.keys(temp.assetsInfo[i]);
      temp.assetsInfo[i] = {
        ...temp.assetsInfo[i],
        isPrinterPollingEnabled: ObjKey.includes('printerPollingEnabled') ?  temp.assetsInfo[i]?.printerPollingEnabled : (isPrinterPolingEnabled || false)
      };
    });
    return {
      ...state,
      [storeId]: {
        ...state[storeId],
        receiptTemplates: temp,
      },
    };
  }),
  on(setPrinterStatus, (state, {status}) => {
    const key = sessionStorage.getItem('location');
    let s: Record<string, IStatus> = status;
    if (status && state[key]?.printerStatus?.devices) {
      s = {...s, ...state?.[key]?.printerStatus?.devices };
      for (const k in state[key].printerStatus?.devices) {
        if (status?.[k]) {
          s[k] = status[k];
        }
      }
    }
    const allConnected = Object.values(s)?.every(
      (o) => o?.isConnected === true
    );
    const disconnectedEvents = [];
    for (const [key, printer] of Object.entries(s)) {
      if (!printer.isConnected) {
        disconnectedEvents.push(`Printer - <span class="pName">${printer.name ?? 'Printer'}</span>\n Ip - ${key}\n ${printer.event})`);
      }
    }
    console.log(disconnectedEvents);
    return {
      ...state,
      [key]: {
        ...state[key],
        printerStatus: {
          devices: { ...state[key]?.printerStatus?.devices, ...s },
          allConnected: allConnected,
          type: state[key]?.printConfig?.type,
          status: disconnectedEvents
          .map(event => `<div class="event-section">${event.replace(/\n/g, '<br>')}</div>`)
          .join('\n')
        },
      },
    };
  }),
  on(setPrinterOffline, (state) => {
    const key = sessionStorage.getItem('location');
    const toast = {
      ...state[key]?.blockUiToast,
      msg: 'Printer is Offline',
      header: 'kiosk.paymentSuccessScreen.printerOutService',
      info: 'kiosk.paymentSuccessScreen.notifyStoreManager',
      showButton: false,
    };
    return {
      ...state,
      [key]: {
        ...state[key],
        blockUiToast: toast,
      },
    };
  }),
  on(loadCounterDataSuccess, (state, {
    data,
    key
  }) => {
    return {
      ...state,
      [key]: {
        ...state[key],
        printerData: {
          ...state[key]?.printerData,
          counterData: data
        },
      },
    };
  }),
  on(isAllowedGoHome, (state, { isGoHomeAllowed }) => {
    const key = sessionStorage.getItem("location");
    return {
      ...state,
      [key]: {
        ...state[key],
        isGoHomeAllowed : isGoHomeAllowed
      },
    };
  })
);
