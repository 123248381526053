import {inject, Injectable} from '@angular/core';
import {AppState} from '../state/app.state';
import {Store} from '@ngrx/store';
import {ConfigService} from './config.service';
import {Subscription} from 'rxjs';
import {getPrintConfig, selectPrinterStatus, selectReceiptTemplates,} from '../state/config/config.selector';
import {DINETYPE, IStatus, PRINT} from '../constants';
import {setPrinterStatus, updatePrinterStatus,} from '../state/config/config.action';
import {IotPrintersService} from '@zdIOTPlatform/ng-iot-printers';
import IminPrinter from 'src/assets/js/imin-printer.esm.browser.min.js';
import {IAssetsInfo} from '../models/config.model';

declare let epson: any;

@Injectable({
  providedIn: 'root',
})
export class PrintReceiptService {
  isKotCheck = [PRINT.KIOSK_ORDER_KITCHEN_TOKEN, PRINT.KIOSK_ORDER_INVOICE];
  printerStatusInterval = null;
  subscriptions: Subscription[] = [];
  orderConstants = {
    DINE_TYPE: {
      TYPES: DINETYPE,
      RULES: {},
    },
    ORDER_SOURCE: {
      TYPES: {
        IOTZEN_POS: 'iotzen-pos',
        KIOSK: 'kiosk',
      },
      RULES: {},
    },
  };
  userName = '';
  printers = inject(IotPrintersService);
  constructor(private conf: ConfigService, private store: Store<AppState>) {
    // console.log(epson);

    this.printers.printerStatus$.subscribe((status) => {
      console.log('printerStatus$', status);
      if (status.error) {
        // this.store.dispatch(setPrinterOffline());
        return;
      }
      if (Object.keys(status).length > 0) {
        this.store.dispatch(
          setPrinterStatus({
            status: status as Record<string, IStatus>,
          })
        );
        return;
      }
    });

    this.subscriptions.push(
      this.store.select(selectPrinterStatus).subscribe((res) => {
        if (!res?.allConnected) {
          this.store.dispatch(
            updatePrinterStatus({
              printerStatusMsg: undefined,
              key: sessionStorage.getItem('location')
                ? sessionStorage.getItem('location')
                : 'default',
            })
          );
        } else {
          this.store.dispatch(
            updatePrinterStatus({
              printerStatusMsg: ' ',
              key: sessionStorage.getItem('location')
                ? sessionStorage.getItem('location')
                : 'default',
            })
          );
        }
      })
    );

    console.log(epson, '20');

    this.printers.init({
      PrinterSettings: this.store.select(getPrintConfig),
      receiptTemplates: this.store.select(selectReceiptTemplates),
      userName: this.userName,
      serverUrl: this.conf.baseUrl,
      iMinSdk: IminPrinter,
      epsonSdk: epson,
    });

  }

  clean() {
    try {
      this.printers.clean();
      return Promise.resolve('clean');
    } catch (e) {
      console.error(e);
    }
  }

  async printReceipt(data: any, tName?: string) {
    return this.printers.printReceipt(data, tName);
  }

  async printReceiptBySocket(template: string, asset: IAssetsInfo) {
  return this.printers.rawPrint(asset, template, asset?.printConfig);
  }

  async retryConnToPrinter(ip: string) {
    return this.printers.retryConnToPrinter(ip);
  }
}
