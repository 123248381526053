import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import Keyboard from 'simple-keyboard';
import {KeyboardLayout, KeyboardThemes} from 'src/app/config';
import {loginError, loginLoader} from 'src/app/state/kioskLogin/kioskLogin.selector';
import {Store} from '@ngrx/store';
import {login, wrongCredential} from 'src/app/state/kioskLogin/kioskLogin.action';
import {AsyncPipe} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {LpinComponent} from './lpin/lpin.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [LpinComponent, FormsModule, AsyncPipe]
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  enterViaLpin = true;
  logoData: string;
  enteredLpin = '';
  lpinInputClicked = false;
  currentLPingInputId = '';
  currentLpinKeyValue = null;
  selectedInputField: any;
  deviceIsMobile = false;
  value = '';
  keyboard: Keyboard = null;
  showLoader$ = this.store.select(loginLoader);
  loginError$ = this.store.select(loginError);

  constructor(
    private router: Router,
    private store: Store
  ) {
    const terminalType = sessionStorage.getItem('terminalType');

    if (sessionStorage.getItem(`${sessionStorage.getItem('accountId')}_token`) !== null && terminalType !== 'kTerminal') {
      this.router.navigate(['stores']);
    } else if (sessionStorage.getItem(`${sessionStorage.getItem('accountId')}_token`) !== null && sessionStorage.getItem('lPin') !== null) {
      if (terminalType !== 'superkTerminal') {
        this.router.navigate(['wallpaper']);
      }
    }
  }

  ngOnInit(): void {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      this.deviceIsMobile = true;
    } else {
      this.deviceIsMobile = false;
    }

    const kpin = JSON.parse(sessionStorage.getItem('kpin'));
    const accountName = sessionStorage.getItem('accountName')
    // this condition is to avoid relogin on page refresh
    if (!accountName) {
      this.setEnteredLpin(kpin?.toString());
    }
  }

  //   input Methods
  onInputFocus(event) {
    if (this.keyboard !== null) {
      this.keyboard.destroy();
    }

    if (!this.enterViaLpin) {
      this.keyboard = new Keyboard({
        onChange: input => this.onChange(input),
        onKeyPress: button => this.onKeyPress(button),
        layout: KeyboardLayout[0],
      });
    }

    this.selectedInputField = event.target.id;
  }

  lPinInputFocused(evt) {
    this.currentLPingInputId = evt;
    if (!this.lpinInputClicked && !this.deviceIsMobile) {
      this.keyboard = new Keyboard({
        onChange: input => this.onChange(input),
        onKeyPress: button => this.onKeyPress(button),
        layout: KeyboardLayout[1],
        theme: KeyboardThemes.numeric
      });
    }

    this.lpinInputClicked = true;
  }

  onChange(input: string) {
    this.value = input;
    if (!this.enterViaLpin) {
      if (this.selectedInputField === 'userName') {
        this.username = input;
      } else if (this.selectedInputField === 'password') {
        this.password = input;
      }
    }
  }

  onKeyPress(button: string) {
    if (this.enterViaLpin) {
      this.currentLpinKeyValue = {
        inputId: this.currentLPingInputId,
        value: button,
      };
    } else if (button === '{bksp}') {
      if (this.selectedInputField === 'userName') {
        this.username = this.username.slice(0, -1);
      } else if (this.selectedInputField === 'password') {
        this.password = this.password.slice(0, -1);
      }
    }
  }

  setEnteredLpin(lpin: string) {
    this.enteredLpin = lpin;
    if (this.enteredLpin?.length > 3 && this.enteredLpin?.length < 5) {
      this.navigateToWallpaper();
    }
  }

  navigateToWallpaper() {
    if (this.enterViaLpin) {
      this.store.dispatch(login({ pin: this.enteredLpin }));
    }
    if (this.username && this.password) {
      const loginCred = {
        username: this.username,
        password: this.password
      };
      this.store.dispatch(login({ email: loginCred }));
    }
  }

  disableClick(e) {
    if (e && e.prevent) {
      e.prevent.default();
    }
  }

  switchPinAndEmail() {
    this.store.dispatch(wrongCredential({ error: false }));

    this.enterViaLpin = !this.enterViaLpin;

    if (this.keyboard !== null) {
      this.keyboard.destroy();
    }

    if (!this.enterViaLpin) {

      this.keyboard = new Keyboard({
        onChange: input => this.onChange(input),
        onKeyPress: button => this.onKeyPress(button),
        layout: KeyboardLayout[0],
        theme: KeyboardThemes.numeric
      });

    }

    if (this.enterViaLpin) {

      this.lpinInputClicked = false;
    }
  }


}



