import {createReducer, on} from '@ngrx/store';
import {Product} from 'src/app/models/product.model';
import {
  addAddonsToCart,
  addGroupedAddonsToCart,
  clearAddons,
  clearCategoryState,
  loadCategoriesSuccess,
  loadFiltersSuccess,
  loadPopularProductsSuccess,
  loadProducts,
  loadProductsByCatId,
  loadProductsByCatIdSuccess,
  loadProductsFailure,
  loadProductsSuccess,
  removeItemCart,
  resetCart,
  selectAddons,
  setAddons,
  setSelectedProduct,
  setSelectedProductC,
  setStoreName,
  sortProducts,
  storeAddOnsDataInState,
  updateCart,
  updateProductFromSocket,
} from './product.action';
import {modProductKeys, getCATProduct, sequencedCategories, mergeUniqueCombos} from './product.reducer.helper';
import {openPopup} from '../checkout/checkout.action';
import {AppConfig} from 'src/app/config';
import {CONSTANTS} from 'src/app/constants';

export const initialState = {
  error: '',
  products: {},
  categories: {},
  filters: {},
  cartProducts: [],
  checkoutData: [],
  selectedProduct: null,
  selectedProductC: null,
  storeName: null,
  totalPrice: 0,
  productLoadFail: false,
  productKey: ''
};

export const ProductsReducer = createReducer(
  initialState,
  on(loadFiltersSuccess, (state, {
    list,
    store
  }) => {
    const updatedFilter = { ...state.filters };
    updatedFilter[store] = new Array(...list).sort((a, b) => a?.info?.label > b?.info?.label ? 1 : -1);
    return {
      ...state,
      filters: updatedFilter,
    };
  }),
  on(resetCart, (state, {
    filter,
    store,
  }) => {
    let products = [];
    const produtIds = [];
    for (let idx = 0; idx < state.cartProducts.length; idx++) {
      if (state.cartProducts[idx]?.metaInfo?.categoryIds) {
        produtIds.push(state.cartProducts[idx]?.metaInfo?.categoryIds);
      }
      if (state.cartProducts[idx]?.groups?.[0]) {
        produtIds.push(state.cartProducts[idx]?.groups?.[0]);
      }
    }
    const updatedProducts = { ...state.products };
    for (let idx = 0; idx < produtIds?.length; idx++) {
      const prodKeyBase = store + produtIds[idx];
      const prodKeyFilter = store + (filter ?  filter : '');
      const prodKeys = filter ? [prodKeyFilter, prodKeyBase] : [prodKeyBase];
      prodKeys.forEach(prodKey => {
        if (state.products[prodKey]) {
          products = [...state.products[prodKey]];
        }
        for (let i = 0; i < products.length; i++) {
          products[i] = {
            ...products[i],
            quantity: 0,
            addedIds: [],
  
          };
          for (let j = 0; j < products[i]?.addOnProds?.length; j++) {
            const p = {
              ...products[i],
              addOnProds: [...products[i]?.addOnProds]
            };
            const newP = { ...p?.addOnProds[j] };
            newP.quantity = 0;
  
            p.addOnProds[j] = newP;
            p.addedIds = [];
            p.productPrice = p.price;
            products[i] = p;
          }
        }
        updatedProducts[prodKey] = products;
      });
    }
    const updatedCategories = { ...state.categories };
    const clonedCategories = [...(updatedCategories[store] || [])];
    clonedCategories.forEach((updatedCategory, i) => {
      updatedCategory = { ...clonedCategories[i] };
      const categoryProdKeyBase = store + updatedCategory?._id;
      const categoryProdKeyFilter = filter ? store + filter : '';
      const categoryProdKeys = filter ? [categoryProdKeyFilter, categoryProdKeyBase] : [categoryProdKeyBase];
      let foundProducts = null;
      categoryProdKeys.forEach(categoryProdKey => {
          if (updatedProducts[categoryProdKey]) {
              foundProducts = updatedProducts[categoryProdKey];
          }
      });
      updatedCategory.products = foundProducts;
      clonedCategories[i] = updatedCategory;
  });
    updatedCategories[store] = clonedCategories;


    return {
      ...state,
      cartProducts: [],
      products: updatedProducts,
      categories: updatedCategories,
    };

  }),
  on(setStoreName, (state, { storeName }) => {
    return {
      ...state,
      storeName
    };
  }),
  on(sortProducts, (state, {
    isAscending,
    filter,
    store
  }) => {
    let products = [];
    const prodKey = store + filter;
    if (state.products[prodKey]) {
      products = [...state.products[prodKey]];
    }

    if (isAscending) {
      products = products.sort((a, b) => a?.metaInfo?.price - b?.metaInfo?.price);
    } else {
      products = products.sort((a, b) => b?.metaInfo?.price - a?.metaInfo?.price);
    }

    // move out of stock products to end
    let key = 0;
    for (let idx = 0; idx < products.length; idx++) {
      if (!products[idx]?.metaInfo?.outOfStockStatus) {
        const temp = products[idx];
        products[idx] = products[key];
        products[key] = temp;
        key++;
      }
    }
    const updatedProducts = { ...state.products };
    updatedProducts[prodKey] = products;

    return {
      ...state,
      products: updatedProducts
    };
  }),
  on(removeItemCart, (state, { product, filter, store }) => {
    let cProducts = [];
    const prodKeyBase = store + product.metaInfo?.categoryIds;
    const prodKeyFilter = store + (filter ?  filter : '');

    const prodKeys = filter ? [prodKeyFilter, prodKeyBase] : [prodKeyBase];

    if (state.cartProducts) {
        cProducts = [...state.cartProducts];
    }

    const updatedProducts = { ...state.products };



    prodKeys.forEach(prodKey => {
        const products = state.products[prodKey] ? [...state.products[prodKey]] : [];

        if (product?.['uId']) {
          // cProducts['addonsCombo'] = cProducts['addonsCombo'].filter(p => p.comboId !== product['uId']);
          // cProducts = cProducts.filter(p => p.uId !== product['uId']);
          cProducts = cProducts.map((cp)=>{
            if(cp.id===product.id){
              const addonsCombo = cp.addonsCombo.filter(p => p.comboId !== product['uId'])
              return {...cp,addonsCombo,quantity:addonsCombo.reduce((sum, obj) => sum + obj.quantity, 0) };
            }
            return cp;
          });
          cProducts = cProducts.filter(p => !!p?.addonsCombo?.length);
          console.log(cProducts,'cProducts')
        } else {
            cProducts = cProducts.filter(p => {
                if (p.id === product.id) {
                    if (p.comboId) {
                        return p;
                    }
                } else {
                    return p;
                }
            });
        }

        for (let i = 0; i < products.length; i++) {
            if (products[i].id === product.id) {
                const quant = cProducts.reduce((acc, currentItem) => {
                    return currentItem.id === products[i].id ? acc + 1 * (currentItem?.quantity) : acc;
                }, 0);
                products[i] = {
                    ...products[i],
                    quantity: quant,
                    productPrice: products?.[i]?.price,
                    addOnProds: products?.[i]?.addOnProds?.map((v) => {
                        const p = { ...v };
                        p.quantity = 0;
                        return p;
                    }),
                    addedIds: [],
                };
            }
        }

        if (products.length) {
            updatedProducts[prodKey] = products;
        }
    });

    let totalPrice = 0;
    for (let i = 0; i < cProducts.length; i++) {
        const product1 = cProducts[i];
        totalPrice += (product1?.comboId ? product1?.productPrice : product1?.price) * product1.quantity;
    }
    const updatedCategories = { ...state.categories };
    const clonedCategories = [...updatedCategories[store]];
    clonedCategories.forEach((updatedCategory, i) => {
      updatedCategory = { ...clonedCategories[i] };
      const categoryProdKeyBase = store + updatedCategory?._id;
      const categoryProdKeyFilter = filter ? store + filter : '';

      const categoryProdKeys = filter ? [categoryProdKeyFilter, categoryProdKeyBase] : [categoryProdKeyBase];

      let foundProducts = null;
      categoryProdKeys.forEach(categoryProdKey => {
          if (updatedProducts[categoryProdKey]) {
              foundProducts = updatedProducts[categoryProdKey];
          }
      });

      updatedCategory.products = foundProducts;
      clonedCategories[i] = updatedCategory;
  });
  updatedCategories[store] = clonedCategories;
    return {
        ...state,
        cartProducts: cProducts,
        products: updatedProducts,
        categories: updatedCategories,
        totalPrice: parseFloat(totalPrice.toFixed(2))
    };
}),
  on(loadProductsSuccess, (state, {
    list,
    filter,
    store,
    buildingState
  }) => {
    const itemsFetched: Record<string, Product[]> = {};
    const prodKey = store + filter;
    const updatedProducts = { ...state.products };
    itemsFetched[prodKey] = [];
    if (!buildingState) {
           for (let i = 0; i < list.length; i++) {
        const prodItem = list[i];
        if (prodItem.product && prodItem?.metaInfo?.price) {
          const item = prodItem.product;
          const metaInfo = prodItem?.metaInfo;
          const url = `${prodItem?.image?.baseUrl}tr:w-300:h-300:di-defaultImage.jpg/${prodItem?.image?.imageBasePath}`;
          const displayUrl = `${prodItem?.image?.baseUrl}tr:w-600:h-600:di-defaultImage.jpg/${prodItem?.image?.imageBasePath}`;
          itemsFetched[prodKey].push({
            id: item._id ? item._id : '6368f7b06636158797ebcef4',
            skuCode: item.skuCode ? item.skuCode : '12345',
            name: metaInfo.itemName ? metaInfo.itemName.toLowerCase()
              .split(' ')
              .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ') : '',
            desc: item.description ? item.description : '',
            pic: 'PROD_IMAGE_URL',
            price: metaInfo.price ? metaInfo.price : null,
            quantity: 0,
            isVeg: item['veg/nonVeg'] ? item['veg/nonVeg'].toLowerCase() === 'veg' : '',
            pId: url,
            display: displayUrl,
            metaInfo,
            regionalLanguage: prodItem?.product?.regionalLanguage,
            groups: prodItem?.groups?.reduce((acc, obj) => {
              acc.push(obj._id);
              return acc;
            }, []),
          } as Product);
        }
      }
           for (let i = 0; i < itemsFetched[prodKey].length; i++) {
        const product = itemsFetched[prodKey][i];
        state.cartProducts?.forEach(cartProduct => {
          if (product?.id === cartProduct?.id) {
            product.quantity = cartProduct.quantity;
          }
        });
      }

      if (itemsFetched && itemsFetched.length) {
        let key = 0;
        for (let idx = 0; idx < itemsFetched.prodKey.length; idx++) {
          if (!itemsFetched.prodKey[idx]?.metaInfo?.outOfStockStatus) {
            const temp = itemsFetched.prodKey[idx];
            itemsFetched.prodKey[idx] = itemsFetched.prodKey[key];
            itemsFetched.prodKey[key] = temp;
            key++;
          }
        }
      }

      updatedProducts[prodKey] = itemsFetched[prodKey].sort((a, b) => a?.id.localeCompare(b?.id));
    } else {
      updatedProducts[prodKey] = new Array(...list).sort((a, b) => a?.id.localeCompare(b?.id));
    }
    return {
      ...state,
      products: updatedProducts,
      productLoadFail: false,
    };
  }),


  on(loadProductsByCatIdSuccess, (state, {
    list,
    filter,
    store
  }) => {
    const prodKey = store + filter;
    const itemsFetched: Record<string, Product[]> = {};
    itemsFetched[prodKey] = [];
    for (let i = 0; i < list?.length; i++) {
      const prodItem = list[i];
      const item = prodItem.product;
      const metaInfo = prodItem?.metaInfo;
      const url = `${prodItem?.image?.baseUrl}tr:w-300:h-300:di-defaultImage.jpg/${prodItem?.image?.imageBasePath}`;
      const displayUrl = `${prodItem?.image?.baseUrl}tr:w-600:h-600:di-defaultImage.jpg/${prodItem?.image?.imageBasePath}`;

      itemsFetched[prodKey].push({
        id: item._id ? item._id : '6368f7b06636158797ebcef4',
        skuCode: item.skuCode ? item.skuCode : '12345',
        name: metaInfo.itemName ? metaInfo.itemName.toLowerCase()
          .split(' ')
          .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ') : '',
        desc: item.description ? item.description : '',
        pic: 'PROD_IMAGE_URL',
        price: metaInfo.price ? metaInfo.price : '123',
        quantity: 0,
        isVeg: item['veg/nonVeg'] ? item['veg/nonVeg'].toLowerCase() === 'veg' : '',
        pId: url,
        display: displayUrl,
        metaInfo,
        groups: prodItem?.groups?.reduce((acc: any[], obj: {
          _id: any;
        }) => {
          acc.push(obj._id);
          return acc;
        }, []),
        regionalLanguage: prodItem?.product?.regionalLanguage
      } as Product);
    }

    itemsFetched[prodKey]?.forEach(product => {
      state.cartProducts?.forEach(cartProduct => {
        if (product?.id === cartProduct?.id) {
          product.quantity = cartProduct.quantity;
        }
      });
    });

    if (itemsFetched?.[prodKey]?.length) {
      let key = 0;
      for (let idx = 0; idx < itemsFetched[prodKey].length; idx++) {
        if (!itemsFetched[prodKey][idx]?.metaInfo?.outOfStockStatus) {
          const temp = itemsFetched[prodKey][idx];
          itemsFetched[prodKey][idx] = itemsFetched[prodKey][key];
          itemsFetched[prodKey][key] = temp;
          key++;
        }
      }
    }

    const updatedProducts = { ...state.products };
    updatedProducts[prodKey] = itemsFetched[prodKey].sort((a, b) => a?.id.localeCompare(b?.id));
    return {
      ...state,
      products: updatedProducts,
    };

  }),
  on(loadProductsFailure, (state, { error }) => ({
    ...state,
    error,
    productLoadFail: true
  })),

  on(loadProducts, (state, {
    filter
  }) => ({
    ...state,
    productKey: filter
  })),

  on(loadProductsByCatId, (state, { categoryId }) => {

    return {
      ...state,
      productKey: categoryId
    };

  }),

  on(updateCart, (state, {
    filter,
    product,
    isAdd,
    store,
    catId,
    changeComboQuantity
  }) => {
    const prodKey = store + catId;
    const dProduct: Product = { ...product };
    let cProducts: Product[] = [...state.cartProducts];
    let products: Product[] = [];
    if (state.products[prodKey]) {
      products = [...state.products[prodKey]];

    }

    let sProduct: Product = { ...state.selectedProduct };
    let index = -1;

    let totalQuantityOfCombos = 0;
    let currentComboPrice = 0;

    // UPDATING CART PRODUCTS
    if (cProducts?.length) {
      for (let i = 0; i < cProducts.length; i++) {
        if (cProducts[i]?.id === dProduct?.id && !cProducts[i]?.comboId) {
          index = i;
          cProducts[i] = { ...cProducts[i] };
          if(changeComboQuantity){
            cProducts[i].addonsCombo = cProducts[i]?.addonsCombo.map((combo)=>{
              if(combo.comboId === dProduct['uId']){
                totalQuantityOfCombos+=dProduct?.['combo'].quantity;
                currentComboPrice = (combo.price / combo.quantity) * dProduct?.['combo'].quantity
                  return {...combo,quantity:dProduct?.['combo'].quantity,price:currentComboPrice}
              }
              totalQuantityOfCombos+=combo.quantity
              return combo
            });
            cProducts[i].quantity = totalQuantityOfCombos;
          }else{
            if (isAdd) {
              ++cProducts[i].quantity;
            } else if (cProducts[i].quantity > 0) {
              --cProducts[i].quantity;
            }
          }
         
        }
      }

    }


    // UPDATING PRODUCTS IN VIEW
    if (products?.length) {
      for (let i = 0; i < products.length; i++) {
        if (products[i]?.id === dProduct?.id) {
          products[i] = { ...products[i] };

          if(changeComboQuantity){
            products[i].addonsCombo = products[i]?.addonsCombo.map((combo)=>{
              if(combo.comboId === dProduct['uId']){
                  return {...combo,quantity:dProduct?.['combo'].quantity,price:currentComboPrice}
              }
              return combo
            });
            products[i].quantity = totalQuantityOfCombos;
          }else{
            if (isAdd) {
              ++products[i].quantity;
  
            } else if (products[i].quantity > 0) {
              --products[i].quantity;
            }
          }
        }
      }

    }


    // ADDING TO CART IF PRODUCT IS NOT ALREADY IN CART
    if (index < 0 && !dProduct?.comboId && !changeComboQuantity) {
      if (isAdd) {
        ++dProduct.quantity;
        dProduct.addedIds = [];
        cProducts.unshift(dProduct);
      } else if (dProduct.quantity > 0) {
        --dProduct.quantity;
      }
    }


    if (index < 0 && dProduct?.comboId && !changeComboQuantity) {
      const comboPresent = cProducts.findIndex((v) => v.comboId === dProduct?.comboId);
      if (comboPresent !== -1) {

      }
      else {
        if (isAdd) {
          ++dProduct.quantity;
          cProducts.unshift(dProduct);
        }
      }
    }



    // REMOVING IF PRODUCT QUANTITY IS 0
    cProducts = cProducts.filter(p => p.quantity !== 0);
    // CALCULATING TOTAL PRICE
    let totalPrice = 0;
    cProducts?.forEach(p => {
      totalPrice += p.price * p.quantity;
    });


    if (!changeComboQuantity) {

      // SETTING QUANTITY IF POPUP SELECTED
      sProduct = {
        ...sProduct,
        quantity: isAdd ? ++sProduct.quantity :
          sProduct.quantity > 0 ? --sProduct.quantity : sProduct.quantity
      };

    }else{
      sProduct.addonsCombo = sProduct?.addonsCombo?.map((combo)=>{
        if(combo.comboId === dProduct['uId']){
            return {...combo,quantity:dProduct?.['combo'].quantity,price:currentComboPrice}
        }
        return combo
      });
      sProduct.quantity = totalQuantityOfCombos;
    }


    const updatedProducts = { ...state.products };
    updatedProducts[prodKey] = products;

    // updation required inside groups also
    if (filter && filter !== catId) {
      const prodKey2 = store + filter;
      let products2: Product[] = [];
      if (state.products[prodKey2]) {
        products2 = [...state.products[prodKey2]];
      }

      if (products2?.length) {
        for (let i = 0; i < products2.length; i++) {
          if (products2[i]?.id === dProduct?.id) {
            products2[i] = { ...products2[i] };

            if(changeComboQuantity){
              products2[i].addonsCombo = products2[i]?.addonsCombo.map((combo)=>{
                if(combo.comboId === dProduct['uId']){
                    return {...combo,quantity:dProduct?.['combo'].quantity,price:currentComboPrice}
                }
                return combo
              });
              products2[i].quantity = totalQuantityOfCombos;
            }else{
              if (isAdd) {
                ++products2[i].quantity;
              } else if (products2[i].quantity > 0) {
                --products2[i].quantity;
              }
            }

           
          }
        }
      }


      updatedProducts[prodKey2] = products2;

    }


    const updatedCategories = { ...state.categories };


    if (updatedCategories?.[store]?.length) {
      const clonedCategories = [...updatedCategories[store]];
      for (let i = 0; i < updatedCategories[store].length; i++) {
        const updatedCategory = { ...clonedCategories[i] };
        updatedCategory.products = updatedProducts[store + updatedCategory?._id];
        clonedCategories[i] = updatedCategory;
        updatedCategories[store] = clonedCategories;

      }
    }

    const selectPCopy = { ...state?.selectedProductC };
    const newInnerProdArr = selectPCopy?.addOnProds?.map((v) => {
      const p = { ...v };
      p.quantity = 0;
      return p
    })

    const newInnerProd = sProduct?.addOnProds?.map((v) => {
      const p = { ...v };
      p.quantity = 0;
      return p;
    })

    sProduct.addOnProds = newInnerProd

    selectPCopy.addOnProds = newInnerProdArr;
    selectPCopy.productPrice = selectPCopy.price;
    delete selectPCopy.comboId;




    return {
      ...state,
      cartProducts: cProducts,
      selectedProduct: sProduct,
      totalPrice: parseFloat(totalPrice.toFixed(2)),
      products: updatedProducts,
      categories: updatedCategories,
      selectedProductC: selectPCopy
    };
  }),
  
  on(updateProductFromSocket, (state, {
    response,
  }) => {
    const updatedProducts = { ...state.products };
    const updatedCategories = { ...state.categories };
    const cProducts: Product[] = [...state.cartProducts];
    const storeId = response?.storeId;
    // response?.data?.map((e)=>{
    //   const prodKey = storeId + e?.metaInfo?.categoryIds;
    //   updatedProducts[prodKey] = updatedProducts[prodKey]?.map((g) => {
    //     return (g?.productInfo?._id === e?.productId) ? {...g, metaInfo: {...g?.metaInfo, outOfStockStatus: e?.metaInfo?.outOfStockStatus, quantity: e?.metaInfo?.quantity, isInventoryEnabled : e?.metaInfo?.isInventoryEnabled ?? g?.metaInfo.isInventoryEnabled}} : g;
    //   });
    // })
    response?.data?.forEach((e) => {
      e?.metaInfo?.categoryIds?.forEach((categoryId) => {
          const prodKey = storeId + categoryId;
          updatedProducts[prodKey] = updatedProducts[prodKey]?.map((g) => {
              return (g?.productInfo?._id === e?.productId) ? {
                  ...g,
                  metaInfo: {
                      ...g?.metaInfo,
                      outOfStockStatus: e?.metaInfo?.outOfStockStatus,
                      quantity: e?.metaInfo?.quantity,
                      isInventoryEnabled: e?.metaInfo?.isInventoryEnabled ?? g?.metaInfo.isInventoryEnabled
                  }
              } : g;
          });
      });
  });
   
    
    if (updatedCategories?.[storeId]?.length) {
         const clonedCategories = [...updatedCategories[storeId]];
         for (let i = 0; i < updatedCategories[storeId].length; i++) {
           const updatedCategory = { ...clonedCategories[i] };
           updatedCategory.products = updatedProducts[storeId + updatedCategory?._id];
           clonedCategories[i] = updatedCategory;
           updatedCategories[storeId] = clonedCategories;
         }
       } 
       response.data.forEach((socketP) => {
        const matchedObj2Index = cProducts.findIndex((obj2) => obj2.productInfo._id === socketP.productId);
        if (matchedObj2Index !== -1) {
            const updatedObj = {...cProducts[matchedObj2Index]};
            updatedObj.metaInfo = {...updatedObj.metaInfo};
            updatedObj.metaInfo.outOfStockStatus = CONSTANTS.OUT_OF_STOCK_STATUS in socketP.metaInfo ? socketP.metaInfo.outOfStockStatus : false;
            updatedObj.metaInfo.quantity =  CONSTANTS.QUANTITY in socketP.metaInfo ? socketP.metaInfo.quantity : -1;
            updatedObj.metaInfo.isInventoryEnabled = CONSTANTS.IS_INVENTORY_ENABLED in socketP.metaInfo ? socketP.metaInfo.isInventoryEnabled: false;
            cProducts[matchedObj2Index] = updatedObj;
        }
    });
    return {
      ...state,
      products: updatedProducts,
      categories: updatedCategories,
      cartProducts: cProducts,
    };
  }),


  // POPUP SELECTED PRODUCT
  on(setSelectedProduct, (state, { product, forAddon }) => {
    const sProduct = { ...product };
    if (forAddon) {
      const addedIds = state?.selectedProduct?.addedIds ?? [];
      sProduct.addedIds = addedIds
      const newProd = sProduct?.addOnProds?.map((v) => {
        const p = { ...v };
        p.quantity = 0;
        return p
      })
      sProduct.addOnProds = newProd
    }
    return {
      ...state,
      selectedProduct: sProduct
    };
  }),

  on(setSelectedProductC, (state, { product, isAdd }) => {
    const sProduct = { ...product };
    if (isAdd) {
      // sProduct.quantity++;
    }
    else {
      // sProduct.quantity--;
    }

    return {
      ...state,
      selectedProductC: sProduct
    };
  }),



  on(openPopup, (state, { popupName, decreasing, comboId }) => {
    let sProductCopy = { ...state.selectedProduct };
    let obj;
    if (popupName === 'showAddOnModal') {
      sProductCopy.quantity = decreasing ? 0 : 1;
      sProductCopy.addedIds = [];
      sProductCopy.productPrice = sProductCopy.price
      const newProdArr = sProductCopy?.addOnProds?.map((v) => {
        const p = { ...v };
        p.quantity = 0;
        return p;
      })
      sProductCopy.addOnProds = newProdArr;
    }

    if (popupName === 'editAddonModal') {
      const comboIdIdx = state.cartProducts.findIndex((v) => v?.comboId === comboId);
      if (comboIdIdx !== -1) {
        obj = state.cartProducts[comboIdIdx];
        sProductCopy = obj;
      }
    }

    return {
      ...state,
      selectedProductC: sProductCopy
    };
  }),


  on(loadCategoriesSuccess, (state, {
    list,
    store
  }) => {
    const updatedCategories = { ...state.categories };
    const updateProducts = { ...state.products };
    const clonedListItem = list.map(item => ({ ...item }));
    updatedCategories[store] = sequencedCategories(clonedListItem);
    let prodKey = '';

    for (let i = 0; i < updatedCategories[store].length; i++) {
      if (updatedCategories[store][i]?.productsCount) {
        prodKey = store + updatedCategories[store][i]?._id;
        updatedCategories[store][i].products = getCATProduct(
          updatedCategories[store][i]?.products).sort((a, b) => a?.id.localeCompare(b?.id)
          );
        updateProducts[prodKey] = updatedCategories[store][i]?.products;
      }
    }

    return {
      ...state,
      categories: updatedCategories,
      products: updateProducts
    };
  }),

  on(clearCategoryState, (state, { store }) => {


    return {
      ...state,
      categories: {
        ...state.categories,
        [store]: []
      }
    };

  }),


  on(setAddons, (state, {
    store
  }) => {
    const updatedProd = { ...state.products };
    const cat = { ...state.categories };
    const updateCat = [...state.categories[store]];
    const addonData = state['addonData'] || [];
    const myMap = new Map();
    for (let idx = 0; idx < Object.keys(state.products)?.length; idx++) {
      if (Object.keys(state.products)[idx].slice(0, 24) === store) {
        const prodKey = Object.keys(state.products)[idx];
        updatedProd[prodKey] = state.products[prodKey]?.map((i) => {
          const product = { ...i, addOnProds: [...i.addOnProds], groupedAddOnProds:[] };

          const groupedAddOnProds = []

          for (let a = 0; a < addonData?.length; a++) {
            for (let p = 0; p < product?.addOnProdIds?.length; p++) {
              if (product?.addOnProdIds[p] === addonData[a]?._id) {
                groupedAddOnProds.push(addonData[a])
                for (let addOnProduct = 0; addOnProduct < addonData[a]?.['addOnProducts']?.length; addOnProduct++) {
                  product.addOnAvailable = true;
                  const data = modProductKeys(addonData[a]?.['addOnProducts'][addOnProduct])
                  if (data) {
                    product.addOnProds.push(data);
                  }
                }
                myMap.set(product.id, product);
              }
            }
          }

          product['groupedAddOnProds'] = groupedAddOnProds.map(ag => {
            return {
              ...ag,selectedQuantity:0, addOnProducts: (ag.addOnProducts || []).map(agp => {
                return { ...agp, isAdded: false }
              })
            }
          })
          return {...product};
        });
      }

    }

    for (let idx = 0; idx < updateCat.length; idx++) {
      const newCatObj = { ...updateCat[idx] };
      for (let j = 0; j < updateCat[idx]?.products?.length; j++) {

        for (const [key, value] of myMap) {
          if (updateCat[idx]?.products[j].id === key) {
            const newProdArray = [...updateCat[idx].products];
            newProdArray[j] = value;
            newCatObj.products = newProdArray;
            updateCat[idx] = newCatObj;
          }
        }
      }
    }
    cat[store] = updateCat;


    return {
      ...state,
      products: updatedProd,
      categories: cat

    };

  }),


  on(addGroupedAddonsToCart, (state, {
    productId,
    store,
    selectedP,
    groupedAddOnProds,
    modfiedAddOnCombos,
    editMode,
    comboPrice,
    comboName
  }) => {
    const updateCat = { ...state.categories };
    let tempObj: Product;
    const cat = [...updateCat[store]];
    const updatedProd = { ...state.products };
    const selectedProduct = { ...(state.cartProducts.find(({ id }) => id === selectedP.id) || selectedP) };

    for (let idx = 0; idx < cat?.length; idx++) {
      const newCat = { ...cat[idx] };
      newCat.products = cat[idx]?.products?.map((p) => {
        if (p?.id === productId) {

          let addonsCombo = modfiedAddOnCombos?.filter(({ quantity }) => (!!quantity)) || [];

          if (selectedP.quantity && !editMode) {
            addonsCombo = [...(addonsCombo), {
              combo: groupedAddOnProds, 
              quantity: selectedP.quantity, 
              price:comboPrice,
              comboName,
              comboId: ''
            }];
          }

          const quantity = addonsCombo.reduce((sum, obj) => sum + obj.quantity, 0);

          const modProduct = { ...p, addonsCombo : mergeUniqueCombos(addonsCombo,selectedP.id), quantity }
          tempObj = modProduct;
          return modProduct
        }
        return p;

      });
      cat[idx] = newCat;
      updateCat[store] = cat;

    }
    for (let idx = 0; idx < Object.keys(updatedProd)?.length; idx++) {
      if (Object.keys(updatedProd)[idx].slice(0, 24) === store) {
        for (let j = 0; j < updatedProd[Object.keys(updatedProd)[idx]]?.length; j++) {
          const newArr = [...updatedProd[Object.keys(updatedProd)[idx]]];
          if (updatedProd[Object.keys(updatedProd)[idx]][j]?.id === productId) {
            newArr[j] = tempObj;
            updatedProd[Object.keys(updatedProd)[idx]] = newArr;
          }
        }
      }
    }
    let cartP = [...state.cartProducts];

    const pIndex = cartP.findIndex(({ id }) => id === tempObj.id)

    if (pIndex < 0) {
      cartP.push(tempObj);
    } else {
      cartP[pIndex] = tempObj
    }

    let totalPrice = 0;
    for (let i = 0; i < cartP.length; i++) {
      totalPrice += (cartP[i].price) * cartP[i].quantity;
    }

    selectedProduct.productPrice = selectedProduct.price;
    
    const cartFinal = cartP.filter((v) => v.quantity > 0);
    const selectPCopy = { ...state?.selectedProductC };

    return {
      ...state,
      categories: updateCat,
      products: updatedProd,
      selectedProduct,
      cartProducts: cartFinal,
      totalPrice,
      selectedProductC: selectPCopy

    };
  }
  ),

  on(addAddonsToCart, (state, {
    productId,
    store,
    selectedP,
    editMode,
    lastComboId
  }) => {
    const updateCat = { ...state.categories };
    let tempObj: Product;
    const cat = [...updateCat[store]];
    const updatedProd = { ...state.products };
    const selectedProduct = {
      ...selectedP,
      addOnProds: [...selectedP.addOnProds],
      addedIds: [...selectedP.addedIds]
    };

    for (let idx = 0; idx < cat?.length; idx++) {
      const newCat = { ...cat[idx] };
      newCat.products = cat[idx]?.products?.map((p) => {
        const v = { ...p }
        if (v?.id === productId) {
          v.addedIds = selectedProduct.addedIds?.length ? selectedProduct.addedIds : v.addedIds;
          if (!editMode) {
            v.quantity += selectedProduct.quantity;
          }
          else {

            // remove the replaceable combo quanitity from total quantity and add new coombo quantity
            const idOfReplaceAbleCombo = state.cartProducts.findIndex((v) => v?.comboId === lastComboId);

            v.quantity = v.quantity + selectedProduct.quantity - state.cartProducts[idOfReplaceAbleCombo].quantity;
          }
          tempObj = v;
        }
        return v;

      });
      cat[idx] = newCat;
      updateCat[store] = cat;

    }

    for (let idx = 0; idx < Object.keys(updatedProd)?.length; idx++) {
      if (Object.keys(updatedProd)[idx].slice(0, 24) === store) {
        for (let j = 0; j < updatedProd[Object.keys(updatedProd)[idx]]?.length; j++) {
          const newArr = [...updatedProd[Object.keys(updatedProd)[idx]]];
          if (updatedProd[Object.keys(updatedProd)[idx]][j]?.id === productId) {

            newArr[j] = tempObj;
            updatedProd[Object.keys(updatedProd)[idx]] = newArr;
          }
        }
      }
    }

    let cartP = [...state.cartProducts];
    let comboId = selectedProduct.addedIds.sort((a, b) => a?.localeCompare(b)).reduce((acc, curr) => {
      return acc + curr;
    }, '');
    comboId = comboId ? selectedProduct?.id + comboId : ''

    const sp = { ...selectedProduct };
    sp.comboId = comboId;

    let index = -1;
    for (let i = 0; i < cartP.length; i++) {
      if (sp.comboId && cartP[i].comboId && cartP[i].comboId === sp.comboId && !editMode) {
        index = i;
        const p = { ...cartP[index] }
        p.quantity += sp.quantity;
        cartP[index] = p;
      }
    }
    if (editMode) {
      const editAbleId = cartP.findIndex((v) => v?.comboId === lastComboId);
      //check if new newComboID is already present or not and based on that push the new or increase already existing quantity
      const newComboID = cartP.findIndex((v) => v?.comboId === sp.comboId);
      if (newComboID === -1) {
        //replacing the old combo with new combo
        cartP[editAbleId] = sp;
        index = editAbleId
      }
      else {
        // increase the quantity of already existing combo of same comboId
        const p = { ...cartP[newComboID] };
        p.quantity += sp.quantity;
        cartP[newComboID] = p;
        // remove the old combo also, because it's getting merge into an already existing combo
        cartP = cartP.filter((v) => v?.comboId !== lastComboId);
        index = editAbleId

      }
    }
    if (index === -1) {
      if (sp.comboId) {
        cartP.push(sp);
      }
      else {
        const baseProductPresent = cartP.findIndex((v) => (v.id === sp.id) && (!v.comboId))
        if (baseProductPresent !== -1) {
          const p = { ...cartP[baseProductPresent] };
          p.quantity += sp.quantity;
          cartP[baseProductPresent] = p;

        }
        else {
          cartP.push(sp)
        }
      }

    }


    let totalPrice = 0;
    for (let i = 0; i < cartP.length; i++) {
      totalPrice += (cartP[i]?.comboId ? cartP[i].productPrice : cartP[i].price) * cartP[i].quantity;

    }

    const cartFinal = cartP.filter((v) => v.quantity > 0);
    selectedProduct.productPrice = selectedProduct.price;
    selectedProduct.addedIds = selectedProduct.addedIds?.length ? selectedProduct.addedIds : state.selectedProduct.addedIds;

    const selectPCopy = { ...state?.selectedProductC };
    const newInnerProdArr = selectPCopy?.addOnProds?.map((v) => {
      const p = { ...v };
      p.quantity = 0;
      return p
    })

    const newInnerP = selectedProduct?.addOnProds?.map((v => {
      const p = { ...v };
      p.quantity = 0;
      return p
    }))

    selectedProduct.addOnProds = newInnerP;

    const anyComboPresent = cartP.findIndex((v => v.comboId));
    if (anyComboPresent === -1) selectedProduct.addedIds = [];

    selectPCopy.addOnProds = newInnerProdArr;

    return {
      ...state,
      categories: updateCat,
      products: updatedProd,
      selectedProduct,
      cartProducts: cartFinal,
      totalPrice,
      selectedProductC: selectPCopy

    };
  }
  ),

  on(selectAddons, (state, {
    addonId,
    isAdd,
    selectedProduct,
    selectedProdc
  }) => {
    const newSelectedProduct = {
      ...(selectedProduct ? selectedProduct : selectedProdc),
      addOnProds: [...(selectedProduct ? selectedProduct.addOnProds : selectedProdc.addOnProds),
      ],
      addedIds: [...(selectedProduct ? selectedProduct.addedIds : selectedProdc.addedIds)]

    };


    for (let idx = 0; idx < newSelectedProduct?.addOnProds?.length; idx++) {
      if (newSelectedProduct?.addOnProds[idx]?.id === addonId) {
        const newProd = { ...newSelectedProduct?.addOnProds?.[idx] };
        if (isAdd) {
          newProd.quantity = 1;
          newSelectedProduct.productPrice += newSelectedProduct.addOnProds[idx]?.price;
        } else {
          newProd.quantity = 0;
          newSelectedProduct.productPrice -= newSelectedProduct.addOnProds[idx]?.price;
        }
        newSelectedProduct.addOnProds[idx] = newProd;

      }
    }
    if (selectedProduct) {
      return {
        ...state,
        selectedProduct: newSelectedProduct
      };
    }
    let comboId = newSelectedProduct.addOnProds.filter(v => v.quantity > 0).map(v => v.id).sort((a, b) => a?.localeCompare(b)).reduce((acc, curr) => {
      return acc + curr;
    }, '')

    comboId = comboId ? newSelectedProduct?.id + comboId : ''


    newSelectedProduct['comboId'] = comboId;
    return {
      ...state,
      selectedProductC: newSelectedProduct
    }
  }),

  on(loadPopularProductsSuccess, (state, { store, data }) => {
    const updatedProducts = { ...state.products };
    const prodKey = store + AppConfig.mlProductFilterName;
    const existingPopularP = updatedProducts[prodKey];
    const updatedPopularProducts = getCATProduct(data)
    const existingPopularPFormat = new Map(existingPopularP?.map(obj => [obj.id, obj]));
    const result = updatedPopularProducts?.map(obj => existingPopularPFormat?.get(obj?.id) || obj)
    updatedProducts[prodKey] = result

    return {
      ...state,
      products: updatedProducts
    }

  }),

  on(storeAddOnsDataInState, (state, { addonData }) => {
    return {
      ...state,
      addonData
    }

  }),


  on(clearAddons, (state, { store }) => {

    // empty these things : -
    // addOnAvailable: boolean,
    // addOnProds: InnerProd[],
    // productPrice ?: number;
    // addedIds ?: string[];
    // addOnProdIds ?: number;
    // comboId: string,
    // fromAddOn ?: boolean

    if (Object.keys(state.categories).length) {
      const updatedProducts = { ...state.products };

      for (const key in updatedProducts) {
        const newProdArr = [];

        for (const value of updatedProducts[key]) {
          const p = { ...value };
          p.addOnAvailable = false;
          p.addOnProds = [];
          p.productPrice = p.price;
          p.addedIds = [];
          p.comboId = '';
          p.fromAddOn = false
          newProdArr.push(p);
        }
        updatedProducts[key] = newProdArr;

      }




      const updatedCategories = { ...state.categories };
      if (updatedCategories?.[store]?.length) {
        const clonedCategories = [...updatedCategories[store]];
        for (let i = 0; i < updatedCategories[store].length; i++) {
          if (updatedCategories?.[store]?.[i]?.products?.length) {
            const updatedCategory = { ...clonedCategories[i] };
            updatedCategory.products = updatedProducts[store + updatedCategory?._id];
            clonedCategories[i] = updatedCategory;
            updatedCategories[store] = clonedCategories;
          }
        }
      }


      return {
        ...state,
        cartProducts: [],
        products: updatedProducts,
        categories: updatedCategories,
      }
    }

  })


)