import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {from, of} from 'rxjs';
import {AuthService} from 'src/app/services/auth.service';
import {getAvailableStores, getAvailableStoresFailure, getAvailableStoresSuccess,getStoreContactDetails,getStoreContactDetailsSuccess,getStorePaymentDetails,getStorePaymentDetailsSuccess} from './stores.action';

@Injectable()
export class StoresAPIEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService
  ) {
  }

  getAvailableStores$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAvailableStores),
      switchMap((action) =>
        from(
          this.authService.getKioskBySuperKioskPing(action.sk_lpin)
        ).pipe(
          map((res) => getAvailableStoresSuccess({ res })),
          catchError((error) => of(getAvailableStoresFailure(error)))
        )
      )
    )
  );

  getStoreContactDetails$ = createEffect(() =>
  this.actions$.pipe(
    ofType(getStoreContactDetails),
    switchMap((action) =>
      from(
        this.authService.loadStoreContactDetails(action.storeId)
      ).pipe(
        map((contactDetails) => getStoreContactDetailsSuccess({ contactDetails,storeId:action.storeId }))
      )
    )
  )
);

getStorePaymentDetails$ = createEffect(() =>
this.actions$.pipe(
  ofType(getStorePaymentDetails),
  switchMap((action) =>
    from(
      this.authService.loadStorePaymentDetails()
    ).pipe(
      map((paymentDetails) => getStorePaymentDetailsSuccess({ paymentDetails,storeId:action.storeId }))
    )
  )
)
);

}
