import { createReducer, on } from '@ngrx/store';
import { getAvailableStoresSuccess, getStoreContactDetailsSuccess, getStorePaymentDetailsSuccess } from './stores.action';


export const initialState = {
  stores: undefined,
  error: "",
  isLoaded: false,
  contactDetails:null,
  paymentDetails:null
}


export const StoresReducer = createReducer(
  initialState,
  on(getAvailableStoresSuccess, (state, { res }) => {
    return {
      ...state,
      stores: res,
      isLoaded: true

    };
  }),

  on(getStoreContactDetailsSuccess, (state, {contactDetails,storeId}) => {
    return {
      ...state,
      contactDetails:
      {
        ...(state?.contactDetails || {}),
        [storeId]:contactDetails
      }
    };
  }),

  on(getStorePaymentDetailsSuccess, (state, { paymentDetails,storeId }) => {
    return {
      ...state,
      paymentDetails:
      {
        ...(state?.paymentDetails || {}),
        [storeId]:paymentDetails
      }
    };
  }),
)


export interface IPaymentDetails {
  _id: string
  gstin: string
  fssai: string
}

export interface IContactDetails {
  _id: string
  name: string
  contactNumber: number
  email: string
}