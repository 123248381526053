
import { Category } from 'src/app/models/categories.model';
import { CategoryProd } from 'src/app/models/categoryProduct.model';
import { Product } from 'src/app/models/product.model';


export const sequencedCategories = (allCategories: CategoryProd[]): CategoryProd[] => {

  const inStockSequenceCategoriesData: CategoryProd[] = allCategories.filter(
    (category: CategoryProd) =>
      !category.outOfStockStatus && category?.categoryInfo.sequence
  );

  const outStockNoSequenceCategoriesData: CategoryProd[] = allCategories.filter(
    (category: CategoryProd) =>
      category.outOfStockStatus || !category?.categoryInfo.sequence
  );

  return [
    ...inStockSequenceCategoriesData.sort(
      (categoryA: CategoryProd, categoryB: CategoryProd) => {
        return categoryA?.categoryInfo.sequence - categoryB?.categoryInfo.sequence;
      }
    ),
    ...outStockNoSequenceCategoriesData,
  ];
};


export const getCATProduct = (list): Product[] => {
  const productArray = [];
  for (let k = 0; k < list.length; k++) {
    const obj = {
      id: list[k]?.productInfo?._id ? list[k]?.productInfo?._id : list[k]._id,
      skuCode: '',
      name: list[k]?.metaInfo?.itemName.toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' '),
      desc: '',
      pic: '',
      quantity: 0,
      price: list[k]?.metaInfo?.price,
      isVeg: list[k]?.productInfo?.['veg/nonVeg'] ? list[k]?.productInfo?.['veg/nonVeg'].toLowerCase() === 'veg' ? true : false : '',
      pId: `${list[k]?.productInfo?.image?.baseUrl}tr:w-300:h-300:di-defaultImage.jpg/${list[k]?.productInfo?.image?.imageBasePath}`,
      display: `${list[k]?.productInfo?.image?.baseUrl}tr:w-600:h-600:di-defaultImage.jpg/${list[k]?.productInfo?.image?.imageBasePath}`,
      metaInfo: list[k]?.metaInfo,
      regionalLanguage: list[k]?.productInfo?.regionalLanguage,
      groups: list[k]?.metaInfo?.groups,
      addOnAvailable: false,
      addOnProds: [],
      productPrice: list[k]?.metaInfo?.price,
      addedIds: [],
      addOnProdIds: list[k]?.metaInfo?.addOns,
      productInfo: list[k]?.productInfo,
    };
    productArray.push(obj);

  }
  return productArray;

};

export const fillAddonProducts = (catProd: Category[], prodId: string) => {
  for (let i = 0; i < catProd?.length; i++) {
    for (let j = 0; j < catProd[i]?.products?.length; j++) {
      if (catProd[i]?.products?.[j]?.id === prodId) {
        return catProd[i]?.products?.[j];
      }
    }
  }
}

export const modProductKeys = (product) =>{
  return {
    ...product,
    "name": product.itemName,
    "metaInfo": {
        "itemName": product.itemName,
        "price": product.price,
    },
    "productPrice": product.price,
}
}

export const mergeUniqueCombos =(addonsCombo,productId:string) =>{
  const addonsComboWithIds = addonsCombo.map((combo)=>{
    const comboId = productId + getComboId(combo.combo)
    return {...combo,comboId}
  });
  const mergedAddonsCombo = [];
  addonsComboWithIds.forEach((combo)=>{
     if(!mergedAddonsCombo.find(({comboId})=>combo.comboId===comboId)){
       const uniqueCombo = addonsComboWithIds.find(({comboId})=>combo.comboId===comboId);
       let quantity = 0;
       let price = 0;
       addonsComboWithIds.forEach((uc)=>{
         if(uc.comboId===combo.comboId){
          quantity+=uc.quantity
          price+=uc.price
         }
       })
 
       mergedAddonsCombo.push({
        ...uniqueCombo,quantity,price
       })
     }
  })
 return mergedAddonsCombo
}

export const getComboId = (selectedAddOns) =>{
  let id = ''
  selectedAddOns.forEach(({addOnProducts})=>{
    addOnProducts.forEach(({isAdded,_id})=>{
      if(isAdded){
        id+=_id
      }
    })
  })
  return id
}
