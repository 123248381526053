import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap, tap, withLatestFrom,} from 'rxjs/operators';
import {from, Observable, of} from 'rxjs';
import {DataService} from 'src/app/services/data.service';
import {Action, Store} from '@ngrx/store';
import {
  createCheckout,
  createCheckoutSuccess,
  discardOrder,
  discardOrderSuccess,
  executeTaxCalculation,
  failure,
  getRazorpayAccountKey,
  getRazorpayAccountKeySuccess,
  getRazorpayImg,
  getRazorpayImgSuccess,
  initiatePayment,
  initiatePaymentSuccess,
  loadQR,
  loadQRSuccess,
  loadStatusQr,
  navigateToHome,
  quantityCheck,
  quantityCheckAndPay,
  quantityCheckSuccess,
  showCheckout,
  updateCheckoutData,
  updateCheckoutDataSuccess,
  updateStatusQr,
} from './checkout.action';
import {updateCart} from '../products/product.action';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {CheckoutRes, PaymentInitiateRes, PluginManagerRes, QrRes,} from 'src/app/models/product.model';
import {getRzpImg, rzpAccountValue, selectCheckoutData,} from './checkout.selector';
import {RzpImg} from 'src/app/models/asset.modal';
// import { TypedAction } from "@ngrx/store/src/models";
import {isInventoryEnabled, isOfflinePaymnetCalculation,} from '../config/config.selector';
import {AppState} from '../app.state';
import {changePaymentGateway} from '../config/config.action';
import {TheKioskOrderService} from 'src/app/services/thekioskorder.service';
import {PAYMENTMODE} from 'src/app/constants';

@Injectable()
export class CheckoutAPIEffects {
  emptyObservable = new Observable((o) => o.next("NoChek"));

  constructor(
    private actions$: Actions,
    private dataService: DataService,
    private route: Router,
    private messageService: MessageService,
    private store: Store<AppState>,
    private kioskOrderService: TheKioskOrderService
  ) {}

  discardOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(discardOrder),
      withLatestFrom(this.store.select(selectCheckoutData)),
      switchMap(([action, data]) => {
        if (data?._id) {
          return from(
            this.dataService.discardOrder(data?._id, action.screen)
          ).pipe(
            map(() => discardOrderSuccess({ checkoutData: [] })),
            catchError((error) => of(failure(error)))
          );
        } else {
          return [];
        }
      })
    )
  );

  createCheckout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCheckout),
      switchMap((action) =>
        from(
          this.dataService.createCheckOut(
            this.kioskOrderService.modifyPayloadForAddOns(action.items,!!action?.taxData),
            action.dineType,
            action?.taxData,
            action?.orderDetails
          )
        ).pipe(
          mergeMap((value: CheckoutRes) => {
            let val = { ...value };
            val["payment"] = action?.taxData || value["payment"];
            val = {...value, payment: {...value.payment , paymentMode: PAYMENTMODE.ONLINE}}
            if (action.routeAfterSuccess) {
              return [
                createCheckoutSuccess({ checkoutData: value }),
                navigateToHome(),
              ];
            } else {
              return [createCheckoutSuccess({ checkoutData: value })];
            }
          }),
          catchError((error) => {
            let detailsMsg = '';
            if(error?.error?.isOutOfStock){
              detailsMsg = error?.error?.statusMsg
            } else {
              detailsMsg = "Not able to proceed checkout, please try again";
            }
              this.messageService.add({
                key: "home",
                severity: "warn",
                summary: "Checkout Failed!",
                detail: detailsMsg,
                sticky: true,
              });
              return of(failure(error)); // Wrap the failure action in `of` to return an observable
          })
        )
      )
    )
  );

  navigateToHome$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigateToHome),
        tap(() => {
          this.route.navigate(["home"]).then((r) => r);
        })
      ),
    { dispatch: false }
  );

  updateCheckoutData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCheckoutData),
      switchMap((action) =>
        from(
          this.dataService.updateOrder(
            this.kioskOrderService.modifyPayloadForAddOns(action.items,!!action?.taxData),
            action.orderId,
            action.dineType,
            action?.taxData,
            action?.orderDetails
          )
        ).pipe(
          switchMap((value) => {
            value["payment"] = action?.taxData || value["payment"];
            value = {...value, payment: {...value.payment, paymentMode: PAYMENTMODE.ONLINE}};
            if (action.routeAfterSuccess) {
              return [
                updateCheckoutDataSuccess({ checkoutData: value }),
                navigateToHome(),
              ];
            } else {
              return [updateCheckoutDataSuccess({ checkoutData: value })];
            }
          }),
          catchError((error) => {
            let detailsMsg = '';
            if(error?.error?.isOutOfStock){
              detailsMsg = error?.error?.statusMsg
            } else {
              detailsMsg = "Not able to update your order due to some error, please try again";
            }
            this.messageService.add({
              key: "home",
              severity: "warn",
              summary: "Order not updated!",
              detail: detailsMsg ,
              sticky: true,
            });
            return [failure(error)];
          })
        )
      )
    )
  );

  getQR$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadQR),
      switchMap((action) =>
        from(this.dataService.getQR(action.orderId)).pipe(
          mergeMap((value: QrRes) => {
            return [
              loadQRSuccess({ QRUrl: value?.image_url, base64: value?.data }),
              changePaymentGateway({
                key: sessionStorage.getItem("location"),
                gateway: value?.paymentGateway,
              }),
            ];
          }),
          catchError((error) => {
            this.messageService.add({
              key: "home",
              severity: "warn",
              summary: "Failed to load QR",
              detail: "Not able to load QR code, please try again",
              sticky: true,
            });
            return [failure(error)];
          })
        )
      )
    )
  );

  initiatePayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initiatePayment),
      switchMap((action) =>
        from(this.dataService.initiatePayment(action.orderId)).pipe(
          map((value: PaymentInitiateRes) =>
            initiatePaymentSuccess({ paymentId: value?.paymentGateway.id })
          ),
          catchError((error) => {
            this.messageService.add({
              key: "home",
              severity: "warn",
              summary: "Can not proceed",
              detail:
                "Not able to proceed dure to some error, please try again",
              sticky: true,
            });
            return [failure(error)];
          })
        )
      )
    )
  );

  quantityCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(quantityCheck),
      withLatestFrom(
        this.store.select(isInventoryEnabled),
        this.store.select(
          isOfflinePaymnetCalculation
        )
      ),
      switchMap(([action, isStockCheck, isPaymentCalculationOffline]) => {
        const actions = new Array<Action<string>>();
        // showCheckout
        if (action.showCheckout) {
          actions.push(showCheckout());
        }
        // Create/Update Checkout
        if (action?.isCheckout) {
          if (isPaymentCalculationOffline) {
            actions.push(
              executeTaxCalculation({
                orderId: action.orderId,
                items: action.items,
                routeAfterSuccess: action.routeAfterSuccess,
                dineType: action.dineType,
              })
            );
          } else {
            if (action.orderId) {
              actions.push(
                updateCheckoutData({
                  items: action.items,
                  orderId: action.orderId,
                  routeAfterSuccess: action.routeAfterSuccess,
                  dineType: action.dineType,
                })
              );
            } else {
              actions.push(
                createCheckout({
                  items: action.items,
                  routeAfterSuccess: action.routeAfterSuccess,
                  dineType: action.dineType,
                })
              );
            }
          }
        }

        // Update Cart if stock check disables
        if (!isStockCheck || !action?.product?.metaInfo?.isInventoryEnabled) {
          if (action?.product) {
            actions.push(
              updateCart({
                product: action.product,
                isAdd: true,
                filter: action.filter,
                store: action.store,
                catId: action.catId,
              }),
              // Need to add this to make loader stop
              quantityCheckSuccess({ data: true })
            );
          }
          // Return here
          return from(this.emptyObservable).pipe(
            mergeMap(() => actions),
            catchError((error) => of(failure(error)))
          );
        } else {
          if(action?.product?.metaInfo?.outOfStockStatus || (action?.product?.quantity + 1 > action?.product?.metaInfo?.quantity)){
            const value = {
              status: false,
              statusMsg : `Requested Quantity is Not Available:<br> - ${action?.product?.name}( Available Quantity: ${action?.product?.metaInfo?.quantity} )`
            }
            actions.push(quantityCheckSuccess({ data: value }));
          } else {
            if (action?.product) {
              actions.push(
                updateCart({
                  product: action.product,
                  isAdd: true,
                  filter: action.filter,
                  store: action.store,
                  catId: action.catId,
                }),
                quantityCheckSuccess({ data: true })
              );
            }
          }
          return from(this.emptyObservable).pipe(
            mergeMap(() => actions),
            catchError((error) => of(failure(error)))
          );
        }

        // return from(this.dataService.quantityCheck(action.items)).pipe(
        //   switchMap((value) => {
        //     // console.log(action.product);
        //     actions.push(quantityCheckSuccess({ data: value }));
        //     // Update Cart
        //     if (value?.status) {
        //       if (action?.product) {
        //         actions.push(
        //           updateCart({
        //             product: action.product,
        //             isAdd: true,
        //             filter: action.filter,
        //             store: action.store,
        //             catId: action.catId,
        //           })
        //         );
        //       }
        //     } else {
        //       actions.push(showOutOfStock({ msg: value?.statusMsg }));
        //     }

            // return actions;
        //   }),
        //   catchError((error) => of(failure(error)))
        // );
      })
    )
  );

  quantityCheckAndPay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(quantityCheckAndPay),
      withLatestFrom(this.store.select(isInventoryEnabled)),
      switchMap(([action, isStockCheck]) => {
        if (!isStockCheck) {
          return [loadQR({ orderId: action.orderId })];
        } else { 
        const isEveryProductsInventoryEnabled = action?.items?.some((e) => e?.metaInfo?.isInventoryEnabled === true);
          let value = this.kioskOrderService.checkQuantities(action.items);
            const actions = [];
            if(isEveryProductsInventoryEnabled){
              actions.push(quantityCheckSuccess({ data: value }));
            }else{
              value = {...value, status: true,statusMsg : ''}
              actions.push(quantityCheckSuccess({ data: true }));
            }
              // const actions = new Array<TypedAction<string>>();
              // actions.push(quantityCheckSuccess({ data: value }));
              if (value?.status) {
                actions.push(loadQR({ orderId: action.orderId }));
              } else {
                // actions.push(closeModal({isOutofStock : value?.status, quantityCheckMsg : value?.statusMsg}));
              }
              return actions;
          //   })
          // );
        }
      })
    )
  );

  getRazorpayAccountKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRazorpayAccountKey),
      withLatestFrom(this.store.select(rzpAccountValue)),
      switchMap(([_, data]) => {
        if (data._id) {
          return [getRazorpayAccountKeySuccess({ res: data })];
        } else {
          return from(this.dataService.getRazorpayAccountKey()).pipe(
            mergeMap((rzpValue: PluginManagerRes) => {
              return [getRazorpayAccountKeySuccess({ res: rzpValue })];
            }),
            catchError((error) => of(failure(error)))
          );
        }
      })
    )
  );

  getRazorpayImg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRazorpayImg),
      withLatestFrom(this.store.select(getRzpImg)),
      switchMap(([_, data]) => {
        if (data) {
          return [];
        } else {
          return from(this.dataService.checkImageForRazorpay()).pipe(
            mergeMap((res: RzpImg) => {
              return [getRazorpayImgSuccess({ img: res })];
            }),
            catchError((error) => {
              return [failure(error)];
            })
          );
        }
      })
    )
  );

  loadStatusQr$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadStatusQr),
      switchMap((action) =>
        this.dataService.getOrderStatusQr(action.orderId).pipe(
          mergeMap((res) => {
            return [updateStatusQr({ img: res?.data })];
          })
        )
      )
    )
  );

  executeTaxCalculation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(executeTaxCalculation),
      switchMap((action) =>
        from(
          this.kioskOrderService.calculateTaxAndParcelCharges(action.items, {
            dineType: action.dineType,
          })
        ).pipe(
          mergeMap((taxData: any) => {
            if (action.orderId) {
              return [
                updateCheckoutData({
                  items: action.items,
                  orderId: action.orderId,
                  routeAfterSuccess: action.routeAfterSuccess,
                  dineType: action.dineType,
                  taxData: taxData.payment,
                  orderDetails: taxData?.orderDetails,
                }),
              ];
            } else {
              return [
                createCheckout({
                  items: action.items,
                  routeAfterSuccess: action.routeAfterSuccess,
                  dineType: action.dineType,
                  taxData: taxData.payment,
                  orderDetails: taxData?.orderDetails,
                }),
              ];
            }
          }),
          catchError((error) => {
            this.messageService.add({
              key: "home",
              severity: "warn",
              summary: "Checkout Failed!",
              detail: "Not able to proceed checkout, please try again",
              sticky: true,
            });
            return [failure(error)];
          })
        )
      )
    )
  );
}
