import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {ICounterData, SettingsUI} from '../models/kioskUiSettings.model';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  baseUrl = environment.BASE_URL;

  constructor(private http: HttpClient) {
  }

  loadConfig(accountId: string, token: string | string[],terminalId: string): Observable<SettingsUI> {
    const headers = new HttpHeaders()
      .set('Authorization', token ? token : sessionStorage.getItem(`${accountId}_token`))
    return this.http.get<SettingsUI>(`${this.baseUrl}api/sok/settings?ngsw-bypass=true&terminalId=${terminalId}`, { headers });
  }
  getCounterData(){
    const storeId = sessionStorage.getItem('location');
    const url = `${this.baseUrl}api/maestro/counters?storeId=${storeId}`;
    return this.http.get<ICounterData[]>(url);
  }
}
