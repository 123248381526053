import {Routes} from '@angular/router';
import {LoginComponent} from './pages/login/login.component';


export const routes: Routes = [
  {
    path: '',
    redirectTo: '/kiosk',
    pathMatch: 'full',

  },
  {
    path: 'kiosk',
    component: LoginComponent,
  },

  {
    path: 'stores',
    loadChildren: () =>
      import('./pages/stores/stores-routing.module').then(
        (m) => m.StoresRoutingModule
      ),
  },
  {
    path: 'menu',
    loadChildren: () =>
      import('./pages/Menu/menu-routing.module').then(
        (m) => m.MenuRoutingModule
      ),
  },
  {
    path: 'wallpaper',
    loadChildren: () =>
      import('./pages/wallpaper/wallpaper-routing.module').then(
        (m) => m.WallpaperRoutingModule
      ),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home-routing.module').then(
        (m) => m.HomeRoutingModule
      ),
  },
];
