import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import {
  buildCategoryProd,
  buildCategoryProdError,
  buildProductState,
  downloadSalesReport,
  loadAddons,
  loadCategoriesSuccess,
  loadFilters,
  loadFiltersFailure,
  loadFiltersSuccess,
  loadMinProducts,
  loadPopularProducts,
  loadPopularProductsFailure,
  loadPopularProductsSuccess,
  loadProducts,
  loadProductsByCatId,
  loadProductsByCatIdSuccess,
  loadProductsFailure,
  loadProductsSuccess,
  setAddons,
  storeAddOnsDataInState,
} from './product.action';
import { getCATProduct } from './product.reducer.helper';
import { isAddOnEnabled, isPopularEnabled } from '../config/config.selector';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { openPopup } from '../checkout/checkout.action';
import { PrintReceiptService } from 'src/app/services/print.receipt.service';
import { IServerSalesReportResponse } from 'src/app/models/config.model';

@Injectable()
export class ProductAPIEffects {
  constructor(
    private actions$: Actions,
    private dataService: DataService,
    private store: Store,
    private messageService:MessageService,
    private printService: PrintReceiptService,
  ) {
  }

  // Changed to switch map, because on cat sequence change socket used to push message for all individual category
  loadFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFilters),
      switchMap((action) =>
        from(
          this.dataService.getFiltersList(action.accountId, action.token)
        ).pipe(
          switchMap((filters) => {
            return [
              loadFiltersSuccess({
                list: filters,
                store: action.store
              }),
              buildProductState({
                data: filters,
                store: action.store
              })
            ];
          }),
          catchError((error) => of(loadFiltersFailure(error)))
        )
      )
    )
  );

  buildProductState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(buildProductState),
      switchMap((action) =>
        from(action.data).pipe(
          mergeMap((filters) => {
            return [
              loadProductsSuccess({
                list: getCATProduct(filters.products),
                filter: filters._id,
                store: action.store,
                buildingState: true
              })
            ];
          }),
          catchError((error) => of(loadFiltersFailure(error)))
        )
      )
    )
  );

  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProducts),
      switchMap((action) =>
        from(
          this.dataService.getProducts(action.filter, action.storeId)
        ).pipe(
          map((products) => loadProductsSuccess({
            list: products,
            filter: action.filter,
            store: action.store
          })),
          catchError((error) => of(loadProductsFailure(error)))
        )
      )
    )
  );


  loadMinProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMinProducts),
      switchMap((action) =>
        from(
          this.dataService.getProducts(action.filter, action.storeId)
        ).pipe(
          map((products) => loadProductsSuccess({
            list: products,
            filter: action.filter,
            store: action.store
          })),
          catchError((error) => of(loadProductsFailure(error)))
        )
      )
    )
  );

  loadProductsByCatId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProductsByCatId),
      switchMap((action) =>
        from(
          this.dataService.getCatProdsByCatId(action.categoryId, action.storeId)
        ).pipe(
          switchMap((products) => {
            return [
              loadProductsByCatIdSuccess({
                list: products,
                filter: action.categoryId,
                store: action.store
              })
            ];
          }
          ),
          catchError((error) => of(loadProductsFailure(error)))
        )
      )
    )
  );



  loadPopularProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPopularProducts),
      withLatestFrom(this.store.select(isPopularEnabled)),
      switchMap(([action, enabled]) => {
        if (enabled) {
          return from(
            this.dataService.getPopularProducts(action.store)
          ).pipe(
            switchMap((data) => {
              if (data?.length) {
                return [loadPopularProductsSuccess({ data: data, store: action.store })];
              }
              else {
                return [loadPopularProductsFailure({ store: action.store })]
              }
            }
            ),
            catchError(() => of(loadPopularProductsFailure({ store: action.store })))
          )
        }
        else {
          return []
        }
      })
    )
  );


  // Changed to switch map, because on cat sequence change socket used to push message for all individual category
  buildCategoryProd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(buildCategoryProd),
      switchMap((action) => {
        const storeIds = action.storeId;
        return from(storeIds?.length ? storeIds : [action.store]).pipe(
          switchMap((res) => {
            return from(
              this.dataService.buildCatList(res, action.accountId, action.token)
            ).pipe(
              switchMap((categories, idx) => {
                if (action.loginType) {
                  return [
                    loadCategoriesSuccess({
                      list: categories,
                      store: storeIds?.length ? storeIds[idx] : action.store
                    }),
                    setAddons({
                      store: action.store,
                    })
                  ];
                }
              }),
              catchError((error) => of(buildCategoryProdError(error)))
            );
          })
        );
      })
    )
  );


  loadAddons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAddons),
      withLatestFrom(this.store.select(isAddOnEnabled({ key: sessionStorage.getItem('location') }))),
      switchMap(([action, data]) => {
        if (data || action.settingUpdate) {
          return from(
            this.dataService.getProductsAddons(sessionStorage.getItem('location'))
          ).pipe(
            switchMap((data) => {
              return [
                storeAddOnsDataInState({
                  addonData: data,
                })
              ];
            }
            ),
            catchError((error) => of(loadProductsFailure(error)))
          )
        }
        else {
          return []
        }
      })
    )
  );

  downloadSalesReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(downloadSalesReport),
      switchMap((action) =>
        from(
          this.dataService.downloadSalesReport(action.startTime, action.endTime,action.terminalId,action.storeId)
        ).pipe(
          switchMap((res: IServerSalesReportResponse) => {
            this.printService.printReceiptBySocket(
              res?.content,
              res?.assetDetails
            ).then(() => {
              this.messageService.add({ severity: 'success',key:'download-sales-report-toast', summary: 'Success', detail: 'Print Initiated' });
            }).catch(() => {
            this.messageService.add({ severity: 'error',key:'download-sales-report-toast', summary: 'Error', detail: 'Error Occured' });
            })
            return [openPopup({ popupName: '' })];
          }),
          catchError(() => {
            this.messageService.add({ severity: 'error',key:'download-sales-report-toast', summary: 'Error', detail: 'Error Occured' });
            return []
          })
        )
      )
    )
  );

}
