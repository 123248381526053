import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {Store} from '../models/store.model';
import {Observable} from 'rxjs';
import {Asset, EmailLogin} from '../models/asset.modal';
import {IContactDetails, IPaymentDetails} from '../state/stores/stores.reducer';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  token: string;
  logoData: any;
  accountName: any;

  constructor(private router: Router, private http: HttpClient) {
    const accountId = sessionStorage.getItem('accountId');
    const s = sessionStorage.getItem(`${accountId}_token`);
    if ( s === null) {
      this.router.navigate(['']).then(r => r);
    } else {
      this.token = s;
    }

  }

  login(lpin?: string, email?: EmailLogin): Observable<Asset> {
    const url = `${environment.BASE_URL}api/smartAsset/login`;
    if (lpin) {
      return this.http.post<Asset>(url, { lPin: lpin });
    }
    else {
      return this.http.post<Asset>(url, email);
    }
  }

  getKioskBySuperKioskPing(lPin: any): Observable<Store[]> {
    const finalUrl = `${environment.BASE_URL}api/smartAsset/assetData/field`;
    const data = { lPin, field: 'kiosk' };
    return this.http.post<Store[]>(finalUrl, data);
  }

  loadStoreContactDetails(storeId:string):Observable<IContactDetails>{
    const URL = `${environment.BASE_URL}api/sok/stores/info/id/${storeId}?fI=name,contactNumber,email`;
    return this.http.get<IContactDetails>(URL);
  }

  loadStorePaymentDetails():Observable<IPaymentDetails>{
    const URL = `${environment.BASE_URL}api/account/self/data?fI=gstin,fssai`;
    return this.http.get<IPaymentDetails>(URL);
  }


}
