<div class="verification-code-container">
  <h1 class="lpin-heading">Enter Pin</h1>
  <br />
  <div (click)="handleZoomIn($event)" class="verification-code--inputs">
    <input inputmode="numeric" type="text" maxlength="1" id='input1' (click)="handleZoomIn($event)" pInputNumber
      (focus)="onInputFocus($event)" />
    <input inputmode="numeric" type="text" maxlength="1" id='input2' (click)="handleZoomIn($event)" pInputNumber
      (focus)="onInputFocus($event)" />
    <input inputmode="numeric" type="text" maxlength="1" id='input3' (click)="handleZoomIn($event)" pInputNumber
      (focus)="onInputFocus($event)" />
    <input inputmode="numeric" type="text" maxlength="1" id='input4' (click)="handleZoomIn($event)" pInputNumber
      (focus)="onInputFocus($event)" />
  </div>
  <br />
</div>