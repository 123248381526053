<p-dialog [closable]="false" [modal]="true" [showHeader]="false" [visible]="toastData?.header" header="Header">
  <ng-template #myTemplate let-data pTemplate="content">
    <div class="flex flex-column">
      @if (data?.header) {
        <div class="flex__container">
          <i class="pi pi-exclamation-triangle"></i>
          <h3 style="margin-top: 5px;">{{data?.header|translate}}
          </h3>
        </div>
      }
      @if (data?.header) {
        <hr>
        }
        <div class="flex__container mssg">
          <p class="mssg__info">{{data?.info|translate}}</p>
          @if (toastData?.msg) {
            <p class="mssg__msg" [ngStyle]="{'white-space': 'pre-line'}" [innerHtml]="data?.msg | translate"></p>
          }
        </div>
        @if (data?.showButton) {
          <button style="width: 25%;" (click)="restartApp()" class="p-button-warning"
          label="Okay" pButton type="button"></button>
        }
      </div>
    </ng-template>
    <ng-container
    *ngTemplateOutlet="myTemplate; context: { $implicit: toastData?.header ? toastData:'' }"></ng-container>

  </p-dialog>