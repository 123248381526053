import {Component, HostListener, OnInit} from '@angular/core';
import {NavigationStart, Router, RouterOutlet} from '@angular/router';
import {LangUtilService} from './services/lang.util.service';
import {Store} from '@ngrx/store';
import {AppState} from './state/app.state';
import {resetSessionTimer, showBlockPopUp,} from './state/config/config.action';
import {showToast} from './state/config/config.selector';
import {clearCategoryState} from './state/products/product.action';
import {Subscription} from 'rxjs';
import {fillStateOnPageRefresh} from './state/kioskLogin/kioskLogin.action';
import {PrintReceiptService} from './services/print.receipt.service';
import {AsyncPipe} from '@angular/common';
import {PrinterToastComponent} from './components/printer-toast/printer-toast.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    PrinterToastComponent,
    RouterOutlet,
    AsyncPipe,
  ],
})
export class AppComponent implements OnInit {
  title = 'Kiosk';
  // accessing favicon link tag
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  showToast$ = this.store.select(showToast);
  refreshSubscription: Subscription;
  browserRefresh = false;

  constructor(
    private router: Router,
    private langUtilService: LangUtilService,
    // private update: PwaUpdateService,
    private store: Store<AppState>,
    private print: PrintReceiptService
  ) {
    // this.update.handleUpdate();
    const lang = sessionStorage.getItem('lang_label');
    this.langUtilService.updateUserLanguage(lang);
    this.router.navigate(['']).then((r) => r);
    this.refreshSubscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.browserRefresh = !router.navigated;
        if (this.browserRefresh) {
          // on page refresh we are loading data only for kiosk mode...for super kiosk we are not loading everything
          if (!sessionStorage.getItem('superkTerminalId')) {
            this.store.dispatch(fillStateOnPageRefresh());
          }
        }
      }
    });
  }

  @HostListener('touched', ['$event'])
  @HostListener('click', ['$event'])
  @HostListener('window:scroll', ['$event'])
  @HostListener('touchmove', ['$event'])
  onEvent(_: TouchEvent | MouseEvent | Event): void {
    const storeId = sessionStorage.getItem('location');
    this.store.dispatch(
      resetSessionTimer({
        timerType: 'global',
        key: storeId ? storeId : 'default',
      })
    );
  }

  ngOnInit() {
    this.favIcon.href = sessionStorage.getItem('logoData');
  }

  routeToWallpaper() {
    const storeId = sessionStorage.getItem('location');
    this.store.dispatch(
      showBlockPopUp({
        visible: false,
        key: storeId ? storeId : 'default'
      })
    );
    this.store.dispatch(clearCategoryState({store: storeId}));
    this.router.navigate(['wallpaper']).then((r) => r);
    this.print.clean().then(r => r).catch(console.error);
  }
}
