import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, mergeMap, switchMap, tap, withLatestFrom,} from 'rxjs/operators';
import {from, of} from 'rxjs';
import {AuthService} from 'src/app/services/auth.service';
import {Store} from '@ngrx/store';
import {
  cachedLogin,
  disconnectPaymentSocketConnection,
  fillStateOnPageRefresh,
  getAccountData,
  getAccountDataFailure,
  getAccountDataSuccess,
  getAssetData,
  getAssetDataFailure,
  getAssetDataSuccess,
  login,
  navigateToHome,
  navigateToStores,
  navigateToWallpaper,
  setAuthCredentials,
  socketConnectionForInventoryProductUpdate,
  socketConnectionForPayment,
  socketConnectionForPrintReceipt,
  socketConnectionForProducts,
  socketConnectionForSetting,
  socketConnectionForWallpapers,
  updateWallpaperFromSocket,
  wrongCredential,
} from './kioskLogin.action';
import {Router} from '@angular/router';
import {DataService} from 'src/app/services/data.service';
import {callConfigApi, dataUpdationFromSocket, getAllReceiptTemplates, getPrintInvoice, loadCounterData,} from '../config/config.action';
import {buildCategoryProd, loadFilters, loadPopularProducts, updateProductFromSocket,} from '../products/product.action';
import {CONSTANTS, PAYMENTMODE} from 'src/app/constants';
import {SocketService} from 'src/app/services/socket.service';
import {selectCheckoutData} from '../checkout/checkout.selector';
import {loadStatusQr, productUpdatedBySocketInventory, updatePaymentStatus} from '../checkout/checkout.action';
import {cartProductsSelector} from '../products/product.selector';
import {AppConfig} from 'src/app/config';
import {PrintReceiptService} from 'src/app/services/print.receipt.service';

// import { TypedAction } from "@ngrx/store/src/models";


@Injectable()
export class LoginAPIEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private dataService: DataService,
    private socketService: SocketService,
    private store: Store,
    private printService: PrintReceiptService
  ) {}

  getAssetData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAssetData),
      switchMap((action) =>
        this.authService.login(action.lpin).pipe(
          switchMap((res) => {
            const terminalId = sessionStorage.getItem("kTerminalId");
            const assetSuccess = getAssetDataSuccess({ res });
            const setCredentials = setAuthCredentials({
              res,
              pinLogin: true,
            });
            const accountData = getAccountData({
              token: res.token,
              lPin: action.lpin,
            });
            return [
              assetSuccess,
              setCredentials,
              accountData,
              callConfigApi({
                accountId: res?.assetData?.accountId,
                firsLoad: true,
                token: res.token,
                terminalId: terminalId
              }),
              getAllReceiptTemplates({terminalId}),
              buildCategoryProd({
                loginType: "sKPin",
                storeId: [res?.assetData?.store],
                store: res?.assetData?.store,
                accountId: res?.assetData?.accountId,
                token: res?.token,
              }),

              loadPopularProducts({
                store: res.assetData?.store,
              }),

              loadFilters({
                store: res?.assetData?.store,
                accountId: res?.assetData?.accountId,
                token: res?.token,
              }),
              socketConnectionForSetting({
                accountId: res?.assetData?.accountId,
              }),
              socketConnectionForProducts({
                accountId: res?.assetData?.accountId,
                storeId: res?.assetData?.store,
              }),
              socketConnectionForInventoryProductUpdate({
                accountId: res?.assetData?.accountId,
                storeId: res?.assetData?.store,
              }),
              socketConnectionForWallpapers({
                terminalId: terminalId,
              }),
              socketConnectionForPrintReceipt(),
            ];
          }),
          catchError((error) => of(getAssetDataFailure(error)))
        )
      )
    )
  );

  navigateToWallpaper$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigateToWallpaper),
        tap(() => {
          this.router.navigate(["wallpaper"]).then((r) => r);
        })
      ),
    { dispatch: false }
  );

  navigateToHome$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigateToHome),
        tap(() => {
          this.router.navigate(["home"]).then((r) => r);
        })
      ),
    { dispatch: false }
  );

  navigateToStores$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigateToStores),
        tap(() => {
          this.router.navigate(["stores"]);
        })
      ),
    { dispatch: false }
  );

  getAccountData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAccountData),
      switchMap((action) =>
        this.dataService.getAccountName(action.token).pipe(
          switchMap((res: any) => {
            const accountDataSuccess = getAccountDataSuccess({
              res,
              lPin: action.lPin,
            });
            return [accountDataSuccess];
          }),
          catchError((error) => of(getAccountDataFailure({ error })))
        )
      )
    )
  );

  cachedLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cachedLogin),
      switchMap((action) =>
        this.authService.login(action.loginData.lPin).pipe(
          switchMap((res) => {
            const assetSuccess = getAssetDataSuccess({ res });
            const setCredentials = setAuthCredentials({
              res,
              pinLogin: true,
            });
            const accountData = {
              name: action.loginData.accountName,
              images: {
                logo: {
                  url: action.loginData.logoData,
                },
              },
              _id: "",
            };
            const accountDataSuccess = getAccountDataSuccess({
              res: accountData,
              lPin: action.loginData.lPin,
            });
            return [assetSuccess, setCredentials, accountDataSuccess];
          }),
          catchError((error) => of(getAssetDataFailure(error)))
        )
      )
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      switchMap((action) =>
        this.authService.login(action.pin, action.email).pipe(
          switchMap((res) => {
            const actionsToDispatch = [];
             actionsToDispatch.push( 
              setAuthCredentials({
                res,
                pinLogin: !!action.pin,
              }),
              getAccountData({
                token: res.token,
                lPin: action.pin,
              }),
             );
            if (res?.assetData?.assetType === CONSTANTS.KIOSK_TYPE.KIOSK_TERMINAL) {
              sessionStorage.setItem('counterPrinting', JSON.stringify(res?.assetData?.counterPrinting));
              actionsToDispatch.push(
                // navigateToWallpaper(),
                getAssetData({ lpin: res.assetData.lPin })
              );
              if (res?.assetData?.counterPrinting?.enabled) {
                actionsToDispatch.push(loadCounterData());
              }
            }   else {
              sessionStorage.setItem('counterPrinting', JSON.stringify(res?.assetData?.counterPrinting));
              actionsToDispatch.push(navigateToStores());
              if (res?.assetData?.counterPrinting?.enabled) {
                actionsToDispatch.push(loadCounterData());
              }
            }
            return actionsToDispatch;
          }),
          catchError((error) => {
            return of(wrongCredential({ error: error.message }));
          })
        )
      )
    )
  );

  socketConnectionForSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socketConnectionForSetting),
      mergeMap((action) =>
        this.socketService
          .get(`kiosk/account/${action.accountId}`, "settings")
          .pipe(
            withLatestFrom(this.store.select(cartProductsSelector)),
            switchMap(([res, cartProducts]) => {
              console.log('setting Updated');
              if (!cartProducts?.length) {
                const terminalId = sessionStorage.getItem("kTerminalId");
                if (
                  res?.message === AppConfig.slotUpdateMessage ||
                  res?.message === AppConfig.inventoryChange
                ) {
                  const productActions = [];
                  productActions.push(
                    callConfigApi({
                      accountId: res?.accountId,
                      terminalId: terminalId
                    })
                  );
                  if(sessionStorage.getItem("location") !== undefined &&
                  sessionStorage.getItem("location") !== null){
                    productActions.push(
                      buildCategoryProd({
                        storeId: [sessionStorage.getItem("location")],
                        store: sessionStorage.getItem("location"),
                        loginType: "lPin",
                        accountId: res?.accountId,
                      })
                    );
                    productActions.push(
                      loadFilters({
                        store: sessionStorage.getItem("location"),
                        accountId: res?.accountId,
                      })
                    );
                  }
                  return productActions;
                } else {
                  return [
                    callConfigApi({
                      accountId: res?.accountId,
                      terminalId: terminalId
                    }),
                  ];
                }
              } else {
                if (
                  res?.message === AppConfig.slotUpdateMessage ||
                  res?.message === AppConfig.inventoryChange
                ) {
                  return [
                    dataUpdationFromSocket({
                      storeIds: res.storeIds,
                      accountId: res.accountId,
                      updatedData: ["isSettingUpdated", "isProductUpdated"],
                    }),
                  ];
                } else {
                  return [
                    dataUpdationFromSocket({
                      store: res.storeId,
                      accountId: res.accountId,
                      updatedData: ["isSettingUpdated"],
                    }),
                  ];
                }
              }
            })
          )
      )
    )
  );
  
  socketConnectionForProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socketConnectionForProducts),
      switchMap((action) =>
        from(
          this.socketService.get(
            `kiosk/account/${action.accountId}/store/${action.storeId}`,
            "productUpdate"
          )
        ).pipe(
          withLatestFrom(this.store.select(cartProductsSelector)),
          switchMap(([res, cartProducts]) => {
             const productActions = [];
            if (!cartProducts?.length) {
              // return [
                if(sessionStorage.getItem("location") !== undefined &&
                  sessionStorage.getItem("location") !== null) {
                productActions.push(buildCategoryProd({
                  storeId: [sessionStorage.getItem("location")],
                  store: sessionStorage.getItem("location"),
                  loginType: "lPin",
                  accountId: res?.accountId,
                })),
                productActions.push(loadPopularProducts({
                  store: sessionStorage.getItem("location"),
                })),
                productActions.push(loadFilters({
                  store: sessionStorage.getItem("location"),
                  accountId: res?.accountId,
                }))
              // ];
                }
              return productActions;
            } else {
              if(sessionStorage.getItem("location") !== undefined &&
                  sessionStorage.getItem("location") !== null) {
              return [
                dataUpdationFromSocket({
                  store: sessionStorage.getItem("location"),
                  accountId: res?.accountId,
                  updatedData: ["isProductUpdated"],
                }),
              ];
            }else{
              return []
            }
            }
          })
        )
      )
    )
  );
  socketConnectionForInventoryProductUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socketConnectionForInventoryProductUpdate),
      switchMap((action) =>
        from(
          this.socketService.get(
            `kiosk/account/${action.accountId}/store/${action.storeId}`,
            "inventoryUpdate"
          )
        ).pipe(
          switchMap((res:any) => {
            if(res?.data?.length){
              return [updateProductFromSocket({response: res}),productUpdatedBySocketInventory({isUpdated : true})];
            } else {
              return []
            }
          })
        )
      )
    )
  );
  socketConnectionForWallpapers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socketConnectionForWallpapers),
      switchMap((action) =>
        from(
          this.socketService.get(
            `kiosk/terminal/${action.terminalId}`,
            "infoUpdate"
          )
        ).pipe(
          switchMap((res: any) => {
            if(res?.data?.assetData?.wallpaper?.length){
              return [updateWallpaperFromSocket({data: res})];
            } else {
              return []
            }
          })
        )
      )
    )
  )

  socketConnectionForPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socketConnectionForPayment),
      withLatestFrom(this.store.select(selectCheckoutData)),
      switchMap(([, data]) => {
        if (data) {
          return this.socketService
            .get(`kiosk/payment/orderId/${data?._id}`, "paymentUpdate")
            .pipe(
              switchMap(() => {
                return [
                  updatePaymentStatus(),
                  getPrintInvoice({
                    isOrderPaid: true,
                    orderId: data?._id,
                    fromPaymentScreen: false,
                    pMode: PAYMENTMODE.ONLINE
                  }),
                  loadStatusQr({ orderId: data?._id }),
                  disconnectPaymentSocketConnection({ orderId: data?._id }),
                ];
              })
            );
        } else {
          return [];
        }
      })
    )
  );

  disconnectPaymentSocketConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(disconnectPaymentSocketConnection),
      switchMap((action) => {
        this.socketService._disconnect(
          `kiosk/payment/orderId/${action?.orderId}`
        );
        return [];
      })
    )
  );

  fillStateOnPageRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fillStateOnPageRefresh),
      switchMap(() => {
        const key = sessionStorage.getItem("location");
        const accountId = sessionStorage.getItem("accountId");
        const terminalId = sessionStorage.getItem("kTerminalId");
        if (key && accountId) {
          return [
            callConfigApi({
              accountId,
              firsLoad: true,
              terminalId: terminalId
            }),
            buildCategoryProd({
              loginType: "sKPin",
              storeId: [key],
              store: key,
              accountId,
            }),
            loadPopularProducts({
              store: key,
            }),
            loadFilters({
              store: key,
              accountId,
            }),
            socketConnectionForSetting({ accountId }),
            socketConnectionForProducts({
              accountId,
              storeId: key,
            }),
            socketConnectionForInventoryProductUpdate({
              accountId,
              storeId: key,
            }),
            socketConnectionForWallpapers({
              terminalId: terminalId,
            }),
            socketConnectionForPrintReceipt(),
            getAllReceiptTemplates({terminalId})
          ];
        } else {
          return [];
        }
      })
    )
  );
  socketConnectionForPrintReceipt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socketConnectionForPrintReceipt),
      switchMap(() => {
        return this.socketService
          .get(
            `kiosk/terminal/${sessionStorage.getItem("kTerminalId")}`,
            "print"
          )
          .pipe(
            switchMap((res: any) => {
              res.dataArr.map((g) => {
                this.printService.printReceiptBySocket(
                  g?.content,
                  g?.assetDetails
                );
              });
              return [];
            })
          );
      })
    )
  );
}
