import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, Observable, throwError, timeout } from "rxjs";
import { environment } from "src/environments/environment";
import { AppConfig } from "../config";
import {
  PrintContent,
  PrinterStatusRes,
  WindowReceiptContent,
} from "../models/asset.modal";

@Injectable({
  providedIn: "root",
})
export class PrinterService {
  constructor(private http: HttpClient) {}

  getPrintContent(
    orderId: string,
    isOrderPaid: boolean
  ): Observable<PrintContent> {
    const BASE_URL = environment.BASE_URL;

    const URL = isOrderPaid
      ? `${BASE_URL}${AppConfig.endPoints.invoiceContent}${orderId}`
      : `${BASE_URL}${AppConfig.endPoints.tokenContent}${orderId}`;

    return this.http.get<PrintContent>(URL);
  }

  getPrinterOnlineStatus(): Observable<PrinterStatusRes> {
    const printerStatusUrl = AppConfig.printStatusUrl;
    const payload = JSON.stringify({
      printer: {
        type: "epson",
      },
    });
    return this.http.post<PrinterStatusRes>(printerStatusUrl, payload).pipe(
      timeout(60000),
      catchError((error) => {
        if (error.status !== 0) {
          const customError = {
            status: 504,
            message: "API request timed out",
          };
          return throwError(() => customError);
        }
        return throwError(() => error);
      })
    );
  }

  print(
    invoiceContent: string,
    orderId?: string
  ): Observable<WindowReceiptContent | PrintContent> {
    const payload = JSON.stringify({
      printer: {
        type: "epson",
      },
      content: invoiceContent,
    });

    const URL = AppConfig.printerURL;

    return this.http.post<PrintContent>(URL, payload).pipe(
      catchError(() => {
        return this.http.get<WindowReceiptContent>(
          `${environment.BASE_URL}${AppConfig.endPoints.printPreview}${orderId}`
        );
      })
    );
  }
}
