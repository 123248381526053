import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, mergeMap, skip, switchMap, takeUntil, withLatestFrom,} from 'rxjs/operators';
import {from, of, timer} from 'rxjs';
import {ConfigService} from 'src/app/services/config.service';
import {
  callConfigApi,
  callPrinterOffline,
  callPrinterOnline,
  changeSelectedLanguage,
  checkPrinterStatusOnline,
  configApiFailure,
  configApiSuccess,
  dataUpdationFromSocket,
  getAllReceiptTemplates,
  getAllReceiptTemplatesSuccess,
  getAllTaxDetails,
  getAllTaxDetailsSuccess,
  getPrintInvoice,
  getTokenContent,
  getUpdatedData,
  isAllowedGoHome,
  loadCounterData,
  loadCounterDataSuccess,
  printProcessFail,
  printSuccess,
  resetSessionTimer,
  restartSlotTimer,
  runSlotMatchInterval,
  setPaymentModeOffline,
  setprintPreviewImg,
  showBlockPopUp,
  stopPrinterPolling,
  submitRatingFeedback,
  submitRatingFeedbackSuccess,
  switchDinningStatus,
  switchTheme,
} from './config.action';
import {LangUtilService} from 'src/app/services/lang.util.service';
import {ThemeService} from 'src/app/services/theme.service';
import {select, Store} from '@ngrx/store';
import {
  autoPrintInvoice,
  autoPrintToken,
  getConfigData,
  getDefaulLanguage,
  isBrowserPrintingEnable,
  stopSlotTimer,
} from './config.selector';
import {AppConfig} from 'src/app/config';
import {formatCurrentTime, formatSlotsStarTime} from './config.helper';
import {PrinterService} from 'src/app/services/printer.service';
import {DataService} from 'src/app/services/data.service';
// import { TypedAction } from "@ngrx/store/src/models";
import {buildCategoryProd, clearAddons, loadAddons, loadFilters,} from '../products/product.action';
import {cartProductsSelector} from '../products/product.selector';
import {PrintReceiptService} from 'src/app/services/print.receipt.service';
import {selectCheckoutData} from '../checkout/checkout.selector';
import {PAYMENTMODE, PRINT} from 'src/app/constants';
import {MessageService} from 'primeng/api';
import {clearCheckout} from '../checkout/checkout.action';
import { navigateToHome, navigateToWallpaper } from '../kioskLogin/kioskLogin.action';

@Injectable()
export class ConfigAPIEffects {
  constructor(
    private actions$: Actions,
    private configService: ConfigService,
    private langUtilService: LangUtilService,
    private theme: ThemeService,
    private store: Store,
    private printerService: PrinterService,
    private dataService: DataService,
    private printReceiptTemplate: PrintReceiptService,
    private messageService: MessageService,
  ) {}

  callConfigApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(callConfigApi),
      switchMap((action) =>
        from(
          this.configService.loadConfig(action.accountId, action.token,action.terminalId)
        ).pipe(
          withLatestFrom(this.store.select(getConfigData)),
          switchMap((res) => {
            const themeConfig = {
              theme: res[0]?.kioskUISettings?.theme?.default,
              palette:
                res[0]?.kioskUISettings?.theme?.colors?.[
                  res[0]?.kioskUISettings?.theme?.default
                ],
            };

            if (themeConfig?.theme && themeConfig?.palette) {
              this.theme.changeTheme(themeConfig);
            }
            const configData = res[0];

            const actions = [];
            const location = sessionStorage.getItem("location");
            if (
              res[0]?.kioskUISettings?.miscellaneous?.showAddons  && action.firsLoad
            ) {
              actions.push(
                loadAddons({ store: location, settingUpdate: true })
              );
            } else {
              if (!action.firsLoad)
                actions.push(clearAddons({ store: location }));
            }
            if (!res[1]?.isPrinterPollingEnabled) {
              actions.push(
                stopPrinterPolling({
                  isPrinterPollingEnabled: true,
                  key: location,
                }),
                checkPrinterStatusOnline({ enablePolling: true })
              );
            } else {
              actions.push(showBlockPopUp({ visible: false, key: location }));
            }
           if(configData?.kioskUISettings?.miscellaneous?.wallpaper && configData?.kioskUISettings?.miscellaneous?.wallpaper !== undefined){
             actions.push(navigateToWallpaper());
           } else {
            actions.push(navigateToHome())
           }
            actions.push(
              configApiSuccess({
                data: configData,
                firstLoad: action.firsLoad,
                key: location,
              }),
              changeSelectedLanguage({
                language: configData?.language,
                key: location,
                defaultSet: false,
                isRegionalLanguageSelected:
                  configData?.language?.defaultLanguage?.id !== "en",
              }),
              resetSessionTimer({ timerType: "global", key: location })
            );

            if (
              configData?.slots?.window?.length !==
              res[1]?.slots?.window?.length
            ) {
              actions.push(
                restartSlotTimer(),
                runSlotMatchInterval({
                  slotTimes: formatSlotsStarTime(configData?.slots?.window),
                })
              );
            }

            actions.push(
              switchDinningStatus({
                isDining:
                  !configData?.kioskUISettings?.dineType?.dineIn &&
                  !configData?.kioskUISettings?.dineType?.dineOut
                    ? true
                    : !!configData?.kioskUISettings?.dineType?.dineIn,
                key: location,
              })
            );

            if (action.firsLoad) {
              actions.push(
                runSlotMatchInterval({
                  slotTimes: formatSlotsStarTime(configData?.slots?.window),
                })
              );
            }
            return actions;
          }),
          catchError((error) =>
            of(
              configApiFailure({
                error,
                key: sessionStorage.getItem("location"),
              })
            )
          )
        )
      )
    )
  );
  loadCounterData$ = createEffect(() =>
  this.actions$.pipe(
    ofType(loadCounterData),
    switchMap(() =>
      from(this.configService.getCounterData()).pipe(
        switchMap((res) => {
          return [
            loadCounterDataSuccess({data: res, key: sessionStorage.getItem("location")}),
          ]
        })
      )
    )
  )
);
  changeSelectedLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeSelectedLanguage),
      withLatestFrom(
        this.store.select(
          getDefaulLanguage({ key: sessionStorage.getItem("location") })
        )
      ),
      switchMap(([action, defaultLang]) => {
        const lang = action.defaultSet
          ? defaultLang
          : action?.language?.defaultLanguage;
        this.langUtilService.updateUserLanguage(lang?.id ? lang?.id : "en");
        return [];
      })
    )
  );

  switchTheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(switchTheme),
      switchMap((action) => {
        const themeConfig = {
          theme: action.changeThemeTo,
          palette: action?.themePalettes?.[action.changeThemeTo],
        };
        this.theme.changeTheme(themeConfig);
        return [];
      })
    )
  );

  runSlotMatchInterval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(runSlotMatchInterval),
      switchMap((action) => {
        const slotData = action;
        return timer(0, AppConfig.slotCheckInterval).pipe(
          withLatestFrom(this.store.select(cartProductsSelector)),
          mergeMap(([_, cartProducts]) => {
            const store = sessionStorage.getItem("location");
            const accountId = sessionStorage.getItem("accountId");
            if (slotData.slotTimes.includes(formatCurrentTime())) {
              if (cartProducts?.length) {
                return [
                  dataUpdationFromSocket({
                    store,
                    accountId,
                    updatedData: ["isProductUpdated"],
                  }),
                ];
              } else {
                return [
                  buildCategoryProd({ store, loginType: "lPin", accountId }),
                  loadFilters({ store, accountId }),
                ];
              }
            } else {
              return [];
            }
          }),
          takeUntil(this.store.pipe(select(stopSlotTimer)).pipe(skip(1)))
        );
      })
    )
  );

  // checkPrinterStatusOnline$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(checkPrinterStatusOnline),
  //     mergeMap((_) =>
  //       timer(0, AppConfig.printerPollingDuration).pipe(
  //         mergeMap(() => from(this.printerService.getPrinterOnlineStatus()).pipe(
  //           map((res) => updatePrinterStatus({
  //             printerStatusMsg: res,
  //             key: sessionStorage.getItem('location') ? sessionStorage.getItem('location') : 'default'
  //           })),
  //           catchError((error) => [updatePrinterStatus({
  //             printerStatusMsg: error,
  //             key: sessionStorage.getItem('location') ? sessionStorage.getItem('location') : 'default'
  //           })])
  //         )
  //         ),
  //         takeUntil(this.store.pipe(select(pollingStatus)).pipe(skip(1)))
  //       )
  //     )
  //   )
  // );

  getTokenContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTokenContent),
      withLatestFrom(
        this.store.select(autoPrintToken),
        this.store.select(selectCheckoutData),
        this.store.select(isBrowserPrintingEnable)
      ),
      mergeMap(([action, data, checkoutData, isBrowserPrinting]) => {
        
        if (data) {
          if (isBrowserPrinting) {
            const updateActions = [];
            checkoutData = { ...checkoutData, orderStatus: checkoutData?.pickup?.status ,payment: {...checkoutData?.payment, amount: checkoutData?.payment?.price?.total }};
              updateActions.push(callPrinterOffline({
                checkoutData: checkoutData,
                fromPaymentScreen: action?.fromPaymentScreen,
              }))
              console.log(!sessionStorage.getItem('showPaymentConfirm'));
              if(!sessionStorage.getItem('showPaymentConfirm')){
                updateActions.push(clearCheckout())
              }
              return updateActions;
          } else {
            return from(
              this.printerService.getPrintContent(
                action.orderId,
                action.isOrderPaid
              )
            ).pipe(
              mergeMap((res) => {
                if (data || action.fromPaymentScreen) {
                  return [
                    callPrinterOnline({
                      invoiceContent: res?.content,
                      isOrderPaid: true,
                      orderId: action.orderId,
                    }),
                  ];
                }
                return [printProcessFail({ error: "autoPrint Not Enabled" })];
              })
            );
          }
        } else {
          return [];
        }
      }),
      catchError((error) => of(printProcessFail({ error })))
    )
  );

  getPrintInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPrintInvoice),
      withLatestFrom(
        this.store.select(autoPrintInvoice),
        this.store.select(selectCheckoutData),
        this.store.select(isBrowserPrintingEnable)
      ),
      mergeMap(([action, data, checkoutData, isBrowserPrinting]) => {
        if (data) {
          if (isBrowserPrinting) {
            checkoutData = { ...checkoutData, orderStatus: "placed", 
            payment: {...checkoutData?.payment, paymentMode : checkoutData?.payment?.paymentMode ?? action?.pMode, amount: checkoutData?.payment?.price?.total, amountPaid : checkoutData?.payment?.price?.total, amountToBePaid: 0, status: 'paid'},
            pickup: {...checkoutData.pickup, status: 'placed'}
          };
            return [
              callPrinterOffline({
                checkoutData: checkoutData,
                fromPaymentScreen: action?.fromPaymentScreen,
              }),
              clearCheckout()
            ];
          } else {
            return from(
              this.printerService.getPrintContent(
                action.orderId,
                action.isOrderPaid
              )
            ).pipe(
              mergeMap((res) => {
                if (action.fromPaymentScreen) {
                  return [
                    callPrinterOnline({
                      invoiceContent: res?.content,
                      isOrderPaid: true,
                      orderId: action.orderId,
                    }),
                  ];
                }
                return [printProcessFail({ error: "autoPrint Not Enabled" })];
              })
            );
          }
        } else {
          return [];
        }
      }),
      catchError((error) => of(printProcessFail({ error })))
    )
  );

  callPrinterOnline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(callPrinterOnline),
      mergeMap((action) =>
        from(
          this.printerService.print(action.invoiceContent, action.orderId)
        ).pipe(
          mergeMap((res) => {
            if (res.receiptUrl) {
              return [
                setprintPreviewImg({
                  imgUrl: res.receiptUrl,
                  key: sessionStorage.getItem("location"),
                }),
                isAllowedGoHome({isGoHomeAllowed: false})
              ];
            } else {
              return [printSuccess(),isAllowedGoHome({isGoHomeAllowed: false})];
            }
          })
        )
      ),
      catchError((error) => {
        return [(printProcessFail({ error })),isAllowedGoHome({isGoHomeAllowed: false})]}
      )
    )
  );

  callPrinterOffline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(callPrinterOffline),
      // filter(action => action.fromPaymentScreen),
      mergeMap((action) => {
        console.log('called print offline',action.checkoutData);
        return from(
          this.printReceiptTemplate.printReceipt(action?.checkoutData , action?.checkoutData?.payment?.paymentMode === PAYMENTMODE.CASH ? PRINT.KIOSK_ORDER_BILL : '')
        ).pipe(
          mergeMap(() => {
            if (action.fromPaymentScreen) {
              return [printSuccess(),isAllowedGoHome({isGoHomeAllowed: false})];
            } else {
              return [printProcessFail({ error: "autoPrint Not Enabled" }),isAllowedGoHome({isGoHomeAllowed: false})];
            }
          }),
          catchError((error) => {
            console.error('Error in printing receipt:', error);
            this.messageService.add({
              severity: 'error',
              summary: 'Printer Error',
              key: 'download-sales-report-toast',
              sticky: true,
              detail: `ERROR: ${
                (error?.error?.msg || error?.msg || error.toString()) ?? ''
              }`,
            });
            return [printProcessFail({ error: "autoPrint Not Enabled" }),isAllowedGoHome({isGoHomeAllowed: false})];
          })
        );
      })
    )
  );

  setPaymentModeOffline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPaymentModeOffline),
      mergeMap((action) =>
        from(this.dataService.setPaymentModeOffline(action.orderId)).pipe(
          mergeMap(() => {
            return [
              getTokenContent({
                isOrderPaid: false,
                orderId: action.orderId,
                fromPaymentScreen: false,
              }),
            ];
          })
        )
      )
    )
  );

  getUpdatedData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUpdatedData),
      withLatestFrom(this.store.select(getConfigData)),
      mergeMap(([, res]) => {
        const updateActions = [];
        if (res?.updatedDataTrack?.updatedData?.includes("isSettingUpdated")) {
          const terminalId = sessionStorage.getItem('kTerminalId');
          updateActions.push(
            callConfigApi({ accountId: res?.updatedDataTrack?.accountId,terminalId: terminalId })
          );
        }
        if (res?.updatedDataTrack?.updatedData.includes("isProductUpdated")) {
          updateActions.push(
            buildCategoryProd({
              storeId: res?.updatedDataTrack?.storeIds,
              store: res?.updatedDataTrack?.store,
              loginType: "lPin",
              accountId: res?.updatedDataTrack?.accountId,
            })
          );
          updateActions.push(
            loadFilters({
              store: res?.updatedDataTrack?.store,
              accountId: res?.updatedDataTrack?.accountId,
            })
          );
        }
        updateActions.push(
          dataUpdationFromSocket({
            accountId: "",
            store: "",
            updatedData: [],
          })
        );
        return updateActions;
      })
    )
  );
  submitRatingFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitRatingFeedback),
      mergeMap((action) =>
        from(this.dataService.submitRatingFeedback(action.obj)).pipe(
          switchMap(() => {
            return [
              submitRatingFeedbackSuccess({
                isRatingSubmitted: true,
              }),
            ];
          }),
          catchError(() => [
            submitRatingFeedbackSuccess({
              isRatingSubmitted: false,
            }),
          ])
        )
      )
    )
  );

  getAllTaxDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllTaxDetails),
      switchMap(() =>
        from(this.dataService.loadAllTaxDetails()).pipe(
          switchMap((taxes) => {
            const storeId = "default";
            return [getAllTaxDetailsSuccess({ taxes, storeId })];
          })
        )
      )
    )
  );

  getReceiptTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllReceiptTemplates),
      switchMap((action) =>
        from(this.dataService.loadReceiptTemplates(action.terminalId)).pipe(
          switchMap((templates) => {
            const storeId = sessionStorage.getItem("location");
            return [getAllReceiptTemplatesSuccess({ templates, storeId })];
          })
        )
      )
    )
  );
}
