import {createReducer, on} from '@ngrx/store';
import {loadLocationsFailure, loadLocationsSuccess} from './location.action';

export const initialState = {
  error: '',
  locations: null,
};

export const LocationsReducer = createReducer(
  initialState,
  on(loadLocationsSuccess, (state, {list}) => ({
    ...state,
    isLoading: false,
    locations: list,
  })),
  on(loadLocationsFailure, (state, {error}) => ({
    ...state,
    isLoading: false,
    error,
  })),
)
