import {TranslateLoader} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

export class CustomTranslateHttpLoader implements TranslateLoader {

	constructor(private http: HttpClient) {
	}


	getTranslation(lang: string): Observable<object> {
		const url = `${environment.BASE_URL}api/languageMeta/id/${lang}`;
		return this.http.get(url);
	}
}
