import {APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, isDevMode} from '@angular/core';
import {provideRouter, Router} from '@angular/router';
import {routes} from './app.routes';
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {BrowserAnimationsModule, provideAnimations} from '@angular/platform-browser/animations';
import {BrowserModule, provideClientHydration, withNoHttpTransferCache} from '@angular/platform-browser';
import {provideServiceWorker} from '@angular/service-worker';
import {provideEffects} from '@ngrx/effects';
import {LocationAPIEffects} from './state/locations/location.effects';
import {ProductAPIEffects} from './state/products/product.effects';
import {CheckoutAPIEffects} from './state/checkout/checkout.effects';
import {StoresAPIEffects} from './state/stores/stores.effects';
import {LoginAPIEffects} from './state/kioskLogin/kioskLogin.effects';
import {ConfigAPIEffects} from './state/config/config.effects';
import {provideStore} from '@ngrx/store';
import {LocationsReducer} from './state/locations/location.reducer';
import {ProductsReducer} from './state/products/product.reducer';
import {CheckoutReducer} from './state/checkout/checkout.reducer';
import {StoresReducer} from './state/stores/stores.reducer';
import {LoginReducer} from './state/kioskLogin/kioskLogin.reducer';
import {ConfigReducer} from './state/config/config.reducer';
import {metaReducers} from './state/app.state';
import {provideStoreDevtools} from '@ngrx/store-devtools';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToastModule} from 'primeng/toast';
import {DialogModule} from 'primeng/dialog';
import {HashLocationStrategy, IMAGE_LOADER, ImageLoaderConfig, LocationStrategy} from '@angular/common';
import {AuthInterceptor} from './interceptors/AuthInterceptor';
import * as Sentry from '@sentry/angular';
import {environment} from '../environments/environment';
import {CustomTranslateHttpLoader} from './services/custom.translate.http.loader';
import pk from 'package.json';

console.log('environment.production', environment.production);
if (!isDevMode()) {
  Sentry.init({
    dsn: environment.sentryDns,
    environment: window.location.host,
    enabled: !window.location.host.includes('localhost'),
    release: pk.version,
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    integrations: [
      Sentry.httpContextIntegration(),
      Sentry.browserTracingIntegration(),
      Sentry.browserApiErrorsIntegration(),
      Sentry.browserProfilingIntegration(),
    ],
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
  });
}

function httpLoaderFactory(http: HttpClient) {
  return new CustomTranslateHttpLoader(http);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideClientHydration(withNoHttpTransferCache()),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideEffects([
      LocationAPIEffects,
      ProductAPIEffects,
      CheckoutAPIEffects,
      StoresAPIEffects,
      LoginAPIEffects,
      ConfigAPIEffects,
    ]),
    provideStore({
      Locations: LocationsReducer,
      Products: ProductsReducer,
      Checkout: CheckoutReducer,
      Stores: StoresReducer,
      Asset: LoginReducer,
      Config: ConfigReducer,
    }, {metaReducers}),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: isDevMode(),
      trace: true,
      name: 'iotzen-kiosk',
    }),
    TranslateService,
    MessageService,
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      ReactiveFormsModule,
      ToastModule,
      DialogModule,
      FormsModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        return config.src;
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ]
};
