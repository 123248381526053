import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap,} from 'rxjs/operators';
import {from, of} from 'rxjs';
import {DataService} from 'src/app/services/data.service';
import {loadLocations, loadLocationsFailure, loadLocationsSuccess} from './location.action';

@Injectable()
export class LocationAPIEffects {
  constructor(
    private actions$: Actions,
    private dataService: DataService
  ) {
  }

  loadLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLocations),
      switchMap(() =>
        from(
          this.dataService.getLocations()
        ).pipe(
          map((locations) => loadLocationsSuccess({list: locations})),
          catchError((error) => of(loadLocationsFailure(error)))
        )
      )
    )
  );
}
