
export const formatSlotsStarTime = (data) => {
    const startTimeArr = [];
    for (let idx = 0; idx < data?.length; idx++) {
        const startValue = data?.[idx]?.start;
        const endValue = data?.[idx]?.end;
        const formatedTimeStart = `${startValue.h}${startValue.m}`;
        if (formatedTimeStart !== formatCurrentTime()) {
            startTimeArr.push(formatedTimeStart);
        }
        const endValuePlusOne = endValue.m + 1;
        const formatedTimeEnd = `${endValue.h}${endValuePlusOne}`;
        startTimeArr.push(formatedTimeEnd);

    }
    return startTimeArr;
};

export const formatCurrentTime = (): string => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();

    return `${hours < 12 ? hours % 12 : hours}${minutes}`;
};
