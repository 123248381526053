import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-lpin',
    templateUrl: './lpin.component.html',
    styleUrls: ['./lpin.component.scss'],
    standalone: true
})
export class LpinComponent implements OnInit, OnChanges {
  verificationCode: string[] = [];
  @Input() onScreenKeyPressed;

  inputList: NodeListOf<HTMLInputElement>;

  @Output() emitConstructedCode = new EventEmitter<string>();
  @Output() inputFocused = new EventEmitter<any>();
  constructor() {
  }

  ngOnInit(): void {
    (document.querySelector('#input1') as HTMLElement).focus();
    this.inputList = document.querySelectorAll('.verification-code-container input[type=text]');
    document.querySelectorAll('.verification-code-container input[type=text]').forEach((el: HTMLInputElement) => {
      el.addEventListener('keyup', (event: KeyboardEvent) => {
        document.querySelectorAll('.verification-code-container input[type=text]').forEach((input: HTMLInputElement, i: number) => {
          this.verificationCode[i] = input.value;
        });
        let constructedNumber = '';
        this.verificationCode.forEach((item) => constructedNumber += item);
        this.emitConstructedCode.emit(constructedNumber);
        const val = el.value.replace(/[^0-9]/g, '').toString();
        if (
          val === '1' ||
          val === '2' ||
          val === '3' ||
          val === '4' ||
          val === '5' ||
          val === '6' ||
          val === '7' ||
          val === '8' ||
          val === '9' ||
          val === '0'
        ) {
          (el.nextElementSibling as HTMLElement)?.focus();
        }
        else if (event.key === 'Backspace') {
          (el.previousElementSibling as HTMLElement)?.focus();
        }
      });
    });

    document.querySelectorAll('.verification-code-container input').forEach((el: HTMLInputElement) => {
      el.addEventListener('paste', (event: ClipboardEvent) => {
        (document.querySelector('#txt') as HTMLInputElement).value = event.clipboardData.getData('text/plain');
      });
    });
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.onScreenKeyPressed.currentValue) {
      if (changes.onScreenKeyPressed.currentValue !== changes.onScreenKeyPressed.previousValue) {
        this.changeInputOnScreenPress(changes.onScreenKeyPressed.currentValue);
      }
    }
  }


  handleZoomIn(event) {
    if (event && event.prevent) {
      event.prevent.default();
    }
  }

  onInputFocus(event) {
    this.inputFocused.emit(event.target.id.slice(-1));
  }

  changeInputOnScreenPress({ inputId, value }) {
    this.verificationCode[inputId - 1] = value;
    const val = Number(value);
    if (val >= 0) {
      if (['1' , '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(value)) {
        document.querySelector(`#input${inputId}`).setAttribute('value', value);
        if (!(inputId === '4')) {
          (document.querySelector('#input' + inputId).nextElementSibling as HTMLInputElement).focus();
        }
      }
    }
    else {
      if (value === '{bksp}') {
        document.querySelector(`#input${inputId}`).setAttribute('value', '');
        if (!(inputId === '1')) {
          (document.querySelector(`#input${inputId}`).previousElementSibling as HTMLInputElement).focus();
        }
      }
    }
    let constructedNumber = '';
    this.verificationCode.forEach((item) => constructedNumber += item);
    this.emitConstructedCode.emit(constructedNumber);

  }
}
