import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class ThemeService {


  constructor() {
  }


  getShade(hexColor, magnitude) {
    hexColor = hexColor?.replace(`#`, ``);
    if (hexColor?.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  }
  getOpaque(hexCode, opacity) {
    hexCode = hexCode.replace("#", "");
    const red = parseInt(hexCode.substr(0, 2), 16);
    const green = parseInt(hexCode.substr(2, 2), 16);
    const blue = parseInt(hexCode.substr(4, 2), 16);
    const opaqueRed = Math.round(red + (255 - red) * opacity);
    const opaqueGreen = Math.round(green + (255 - green) * opacity);
    const opaqueBlue = Math.round(blue + (255 - blue) * opacity);
    const opaqueHexCode = `#${opaqueRed.toString(16).padStart(2, '0')}${opaqueGreen.toString(16).padStart(2, '0')}${opaqueBlue.toString(16).padStart(2, '0')}`;

    return opaqueHexCode;
  }


  getTxtColor(hexCode) {
    if (hexCode?.startsWith('#')) {
      hexCode = hexCode?.substr(1);
    }

    const red = parseInt(hexCode?.substr(0, 2), 16);
    const green = parseInt(hexCode?.substr(2, 2), 16);
    const blue = parseInt(hexCode?.substr(4, 2), 16);

    if ((red * 0.299 + green * 0.587 + blue * 0.114) > 186) {
      return '#000';
    }
    return '#fff';

  }

  changeTheme(themeConfig) {
    sessionStorage.setItem('theme', themeConfig?.theme);
    switch (themeConfig?.theme) {
      case 'light':
        document.documentElement.style.setProperty('--primary', themeConfig?.palette?.primary);
        document.documentElement.style.setProperty('--secondary', themeConfig?.palette?.secondary);
        document.documentElement.style.setProperty('--background', themeConfig?.palette?.background);
        document.documentElement.style.setProperty('--accent', themeConfig?.palette?.accent);
        document.documentElement.style.setProperty('--accordianTxt', 'black');
        document.documentElement.style.setProperty('--headerText', this.getTxtColor(themeConfig?.palette?.accent));
        document.documentElement.style.setProperty('--mainText', this.getTxtColor(themeConfig?.palette?.background));
        document.documentElement.style.setProperty('--unselectedText', 'black');
        document.documentElement.style.setProperty('--selectedText', 'black');
        document.documentElement.style.setProperty('--textColor', this.getShade(themeConfig?.palette?.background, -60));
        document.documentElement.style.setProperty('--selectedBtn', themeConfig?.palette?.accent);
        document.documentElement.style.setProperty('--modalBackgroundColor', this.getShade(themeConfig?.palette?.background, -30));
        document.documentElement.style.setProperty('--categoryList', this.getShade(themeConfig?.palette?.background, -50));
        document.documentElement.style.setProperty('--btnQuantityTxt', this.getTxtColor(getComputedStyle(document.body).getPropertyValue('--selectedBtn')));
        document.documentElement.style.setProperty('--highlightedColor', this.getShade(themeConfig?.palette?.secondary, -30));
        document.documentElement.style.setProperty('--categoryButton', this.getShade(themeConfig?.palette?.secondary, -60));
        document.documentElement.style.setProperty('--categoryText', this.getShade(themeConfig?.palette?.secondary, -40));
        document.documentElement.style.setProperty('--scrollbarcolor', this.getShade(themeConfig?.palette?.secondary, -40));
        document.documentElement.style.setProperty('--scrollbarcolor2', this.getShade(themeConfig?.palette?.secondary, -20));
        document.documentElement.style.setProperty('--backdropDarkColor', this.getOpaque(themeConfig?.palette?.background, 0.10));
        document.documentElement.style.setProperty('--backdropLightColor', this.getOpaque(themeConfig?.palette?.background, 0.1));
        document.documentElement.style.setProperty('--secondaryLight', this.getShade(themeConfig?.palette?.secondary, -40));
        document.documentElement.style.setProperty('--boxshadowShade', this.getShade(themeConfig?.palette?.secondary, -15));
        document.documentElement.style.setProperty('--unselectedColor', '#fff');
        document.documentElement.style.setProperty('--badgeColorOpaque', themeConfig?.palette?.primary + 40);
        document.getElementsByTagName('meta').namedItem('theme-color').setAttribute('content', '#321313');
        break;

      case 'dark':
        document.documentElement.style.setProperty('--primary', themeConfig?.palette?.primary);
        document.documentElement.style.setProperty('--secondary', themeConfig?.palette?.secondary);
        document.documentElement.style.setProperty('--background', themeConfig?.palette?.background);
        document.documentElement.style.setProperty('--accent', themeConfig?.palette?.accent);
        document.documentElement.style.setProperty('--accordianTxt', 'wheat');
        document.documentElement.style.setProperty('--headerText', this.getTxtColor(themeConfig?.palette?.accent));
        document.documentElement.style.setProperty('--mainText', this.getTxtColor(themeConfig?.palette?.primary));
        document.documentElement.style.setProperty('--unselectedText', 'white');
        document.documentElement.style.setProperty('--selectedText', 'wheat');
        document.documentElement.style.setProperty('--textColor', this.getShade(themeConfig?.palette?.background, 60));
        document.documentElement.style.setProperty('--selectedBtn', themeConfig?.palette?.accent);
        document.documentElement.style.setProperty('--categoryList', this.getShade(themeConfig?.palette?.background, 50));
        document.documentElement.style.setProperty('--btnQuantityTxt', this.getTxtColor(getComputedStyle(document.body).getPropertyValue('--selectedBtn')));
        document.documentElement.style.setProperty('--highlightedColor', this.getShade(themeConfig?.palette?.secondary, 30));
        document.documentElement.style.setProperty('--categoryButton', this.getShade(themeConfig?.palette?.secondary, 60));
        document.documentElement.style.setProperty('--categoryText', this.getShade(themeConfig?.palette?.secondary, 40));
        document.documentElement.style.setProperty('--modalBackgroundColor', this.getShade(themeConfig?.palette?.background, 30));
        document.documentElement.style.setProperty('--badgeColorOpaque', themeConfig?.palette?.primary + 40);
        document.documentElement.style.setProperty('--unselectedColor', '#3C2015');
        document.documentElement.style.setProperty('--scrollbarcolor', this.getShade(themeConfig?.palette?.secondary, 50));
        document.documentElement.style.setProperty('--scrollbarcolor2', this.getShade(themeConfig?.palette?.secondary, 50));
        document.documentElement.style.setProperty('--backdropDarkColor', this.getOpaque(themeConfig?.palette?.background, 0.10));
        document.documentElement.style.setProperty('--backdropLightColor', this.getOpaque(themeConfig?.palette?.background, 0.10));
        document.documentElement.style.setProperty('--secondaryLight', this.getShade(themeConfig?.palette?.secondary, 40));
        document.documentElement.style.setProperty('--boxshadowShade', this.getShade(themeConfig?.palette?.secondary, 15));
        document.getElementsByTagName('meta').namedItem('theme-color').setAttribute('content', '#000000');
        break;


      default:
        document.documentElement.style.setProperty('--primary', '#000');
        document.documentElement.style.setProperty('--secondary', '#e7a200');
        document.documentElement.style.setProperty('--background', '#3c2015');
        document.documentElement.style.setProperty('--headerText', '#F5DEB3');
        document.documentElement.style.setProperty('--mainText', '#fff');
        document.documentElement.style.setProperty('--accent', '#c89d28');
        document.documentElement.style.setProperty('--accordianTxt', 'wheat');
        document.documentElement.style.setProperty('--unselectedText', 'white');
        document.documentElement.style.setProperty('--unselectedColor', '#3C2015');
        document.documentElement.style.setProperty('--selectedText', 'wheat');
        document.documentElement.style.setProperty('--scrollbarcolor', '#b1812d');
        document.documentElement.style.setProperty('--scrollbarcolor2', '#b1812d');
        document.documentElement.style.setProperty('--modalBackgroundColor', '#3c2015');
        document.getElementsByTagName('meta').namedItem('theme-color').setAttribute('content', '#000000');
        break;
    }


  }
}
