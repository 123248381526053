import { ActionReducer, INIT, MetaReducer } from '@ngrx/store';
import * as UserActions from './app.action';
import { PluginManagerRes, Product } from '../models/product.model';
import { Store } from '../models/store.model';
import { AssetData } from '../models/asset.modal';
import { Filter } from '../models/filter.model';
import { Category } from '../models/categories.model';
import { ConfigReducer } from './config/config.reducer';
import { IContactDetails, IPaymentDetails } from './stores/stores.reducer';

export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action != null && action?.type === UserActions.logout?.type) {
      return reducer(undefined, { type: INIT });
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [logout];


export interface AppState {
  Locations: {
    locations: [],
    error: string
  };
  Products: {
    products: Record<string, Product[]>,
    categories: Record<string, Category[]>
    filters: Record<string, Filter[]>,
    cartProducts: Product[],
    selectedProduct: Product,
    selectedProductC: Product,
    totalPrice: number;
    storeName: string;
    error: string,
    productLoadFail: boolean,
    productKey: string
  };
  Checkout: {
    error: string,
    paymentStatus: boolean,
    statusQr: string,
    isCheckout: boolean,
    checkoutData: any,
    calculatingTax: boolean,
    availablePaymentMode: any,
    isOnlinePaymentEnabled: boolean,
    isOutofStock: boolean,
    quantityCheckMsg: string,
    quantityId: string,
    checkoutBtnLoader: boolean,
    checkoutLoader: boolean,
    QRLoader: boolean,
    QRUrl: string,
    paymentId: string,
    popupName: string,
    paymentMode: any,
    onlinePayment: boolean,
    gateway: string,
    isDinning: boolean,
    rzpAccountData: PluginManagerRes,
    rzpImg: string,
    isproductUpdatedBySocketInventory: boolean
  };
  Stores: {
    stores: Store[],
    error: Error,
    isLoaded: boolean,
    paymentDetails:Record<string,IPaymentDetails>,
    contactDetails:Record<string,IContactDetails>
  };
  Asset: {
    assetData: Record<string, AssetData>
    loginLoader: boolean,
    loginFail: boolean,
    logoUrl: string
  };
  Config: ConfigReducer;
}

export { UserActions };

