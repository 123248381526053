import { createReducer, on } from '@ngrx/store';
import {
  clearCheckout,
  closeModal,
  createCheckoutSuccess,
  destroyQR,
  discardOrderSuccess,
  failure,
  getRazorpayAccountKeySuccess,
  getRazorpayImgSuccess,
  hideCheckoutLoader,
  initiatePaymentSuccess,
  loadQR,
  loadQRSuccess,
  onlinePaymentQRLoadSuccess,
  onlinePaymentStatus,
  openPopup,
  paymentMode,
  productUpdatedBySocketInventory,
  quantityCheckSuccess,
  setPaymentMode,
  showCheckout,
  showCheckoutLoader,
  showOutOfStock,
  showQRLoader,
  showQuantityLoader,
  toggleCheckout,
  updateCheckoutData,
  updateCheckoutDataSuccess,
  updatePaymentStatus,
  updateStatusQr
} from './checkout.action';

export const initialState = {
  error: '',
  isCheckout: false,
  checkoutData: null,
  statusQr: '',
  paymentStatus: false,
  isOnlinePaymentEnabled: false,
  isOutofStock: false,
  quantityCheckMsg: null,
  checkoutBtnLoader: false,
  calculatingTax: false,
  checkoutLoader: false,
  QRLoader: false,
  QRUrl: null,
  paymentId: null,
  quantityId: null,
  popupName: null,
  showModal: false,
  paymentMode: {
    payment: false,
    orderId: null
  },
  onlinePayment: false,
  gateway: '',
  rzpAccountData: {
    apiKey: null,
    customer_id: null,
    linkedAccount: null,
    _id: null
  },
  rzpImg: '',
  isproductUpdatedBySocketInventory: false,
};

export const CheckoutReducer = createReducer(
  initialState,
  on(discardOrderSuccess, (state, { }) => {
    return {
      ...state,
      checkoutData: undefined,
    };
  }),

  on(showCheckout, (state, { }) => {
    return {
      ...state,
      isCheckout: true
    };
  }),

  on(showQRLoader, (state, { }) => {
    return {
      ...state,
      QRLoader: true
    };
  }),

  on(loadQR, (state) => {
    return {
      ...state,
      QRUrl: ''

    }
  }),

  on(loadQRSuccess, (state, { QRUrl, base64 }) => {
    return {
      ...state,
      QRUrl: base64 ? `data:image/png;base64,${base64}` : QRUrl
    };
  }),

  on(initiatePaymentSuccess, (state, { paymentId }) => {
    return {
      ...state,
      paymentId,
    };
  }),

  on(showOutOfStock, (state, { msg }) => {
    return {
      ...state,
      quantityCheckMsg: msg,
      isOutofStock: true
    };
  }),

  on(showCheckoutLoader, (state, { }) => {
    return {
      ...state,
      checkoutBtnLoader: true,
      checkoutLoader: true
    };
  }),

  on(showQuantityLoader, (state, { id }) => {
    return {
      ...state,
      quantityId: id,
    };
  }),

  on(toggleCheckout, (state, { }) => {
    return {
      ...state,
      isCheckout: false,
    };
  }),

  on(createCheckoutSuccess, (state, { checkoutData }) => {
    return {
      ...state,
      checkoutData,
      checkoutLoader: false,
      checkoutBtnLoader: false
    };
  }),

  on(updateCheckoutDataSuccess, (state, { checkoutData }) => {
    return {
      ...state,
      checkoutData,
      calculatingTax: false,
      checkoutLoader: false,
      checkoutBtnLoader: false
    };
  }),

  on(updateCheckoutData, (state) => {
    return {
      ...state,
      calculatingTax: true
    };
  }),


  on(quantityCheckSuccess, (state, { data }) => {
    return {
      ...state,
      isOutofStock: !data.status,
      quantityCheckMsg: data.statusMsg,
      quantityId: null
    };
  }),

  on(closeModal, (state, { }) => {
    // console.log('Close modal')
    return {
      ...state,
      // isOutofStock: false,
      quantityCheckMsg: null
    };
  }),

  on(hideCheckoutLoader, (state, { }) => ({
    ...state,
    checkoutLoader: false,
  })),

  on(destroyQR, (state, { }) => ({
    ...state,
    QRUrl: null,
    QRLoader: false,
  })),

  on(failure, (state, { error }) => ({
    ...state,
    error,
    QRLoader: false,
    quantityId: null
  })),

  on(openPopup, (state, { popupName }) => ({
    ...state,
    popupName
  })),

  on(paymentMode, (state, { paymentOn }) => ({
    ...state,
    paymentMode: paymentOn
  })),

  on(onlinePaymentStatus, (state, { onlinePayment }) => ({
    ...state,
    onlinePayment
  })),

  on(onlinePaymentQRLoadSuccess, (state, { }) => ({
    ...state,
    QRLoader: false

  })),

  on(getRazorpayAccountKeySuccess, (state, { res }) => ({
    ...state,
    rzpAccountData: res
  })),


  on(getRazorpayImgSuccess, (state, { img }) => {
    let logoURL = '';
    if (img.images?.logo?.razorpayDisplayUrl) {
      logoURL = img.images?.logo?.razorpayDisplayUrl;
    } else {
      const img = sessionStorage.getItem('logoData');
      logoURL = `data:image/png;base64,${img}`;
    }

    return {
      ...state,
      rzpImg: logoURL
    };
  }),

  on(updatePaymentStatus, (state) => {
    return {
      ...state,
      paymentStatus: true
    };
  }),

  on(updateStatusQr, (state, { img }) => {
    return {
      ...state,
      statusQr: img
    };
  }),

  on(clearCheckout, (state, { }) => ({
    ...state,
    error: '',
    paymentStatus: false,
    isCheckout: false,
    checkoutData: null,
    calculatingTax: false,
    availablePaymentMode: null,
    isOnlinePaymentEnabled: false,
    isOutofStock: false,
    quantityCheckMsg: null,
    checkoutBtnLoader: false,
    checkoutLoader: false,
    QRLoader: false,
    QRUrl: null,
    paymentId: null,
    quantityId: null,
    popupName: null,
    showModal: false,
    paymentMode: {
      payment: false,
      orderId: null
    },
    razorpay: false

  })),

  on(setPaymentMode, (state, { paymentMode }) => {
    const checkoutData = {
      ...state.checkoutData,
      payment:{
        ...state?.checkoutData?.payment,
        paymentMode
      }
    }
    return {
      ...state,
      checkoutData
    };
  }),

  on(productUpdatedBySocketInventory, (state, { isUpdated }) => {
    return {
      ...state,
      isproductUpdatedBySocketInventory: isUpdated
    };
  }),
);
