import { createReducer, on } from '@ngrx/store';
import { cachedLogin, getAccountDataSuccess, getAssetDataSuccess, login, setAuthCredentials, updateWallpaperFromSocket, wrongCredential } from './kioskLogin.action';
import { CONSTANTS } from 'src/app/constants';


export const initialState = {
  assetData: {},
  loginLoader: false,
  loginFail: false,
  logoUrl: ''
};


export const LoginReducer = createReducer(
  initialState,
  on(getAssetDataSuccess, (state, { res }) => {
    const pin = res?.assetData?.lPin;
    localStorage.setItem('account',JSON.stringify(res?.assetData?.accountData))
    sessionStorage.setItem('accountId', res?.assetData?.accountId);
    if (res?.assetData?.isMiniKiosk) {
      sessionStorage.setItem('isMiniKiosk', 'true')
    }

    if (res?.assetData?.oneStepPayment) {
      sessionStorage.setItem('isOneStopPayment', 'true')
    }

    if (res?.assetData?.isMinimalUI) {
      sessionStorage.setItem('isMinimalUI', 'true')
    }

    if (res?.assetData?.paymentConfirmationScreen) {
      sessionStorage.setItem('showPaymentConfirm', 'true')
    }

    const updatedAsset = { ...state.assetData, [pin]: { ...res.assetData, token: res.token } };
    return {
      ...state,
      assetData: updatedAsset,

    };
  }),

  on(setAuthCredentials, (state, { res, pinLogin }) => {
    sessionStorage.setItem(`${sessionStorage.getItem('accountId')}_token`, res.token);
    if (pinLogin && res.assetData.assetType === CONSTANTS.KIOSK_TYPE.KIOSK_TERMINAL) {

      if (!sessionStorage.getItem('terminalType')) {
        sessionStorage.setItem('terminalType', res.assetData.assetType);
      }

      sessionStorage.setItem('lPin', res.assetData.lPin);
      sessionStorage.setItem('kTerminalId', res.assetData._id);

    } else if (pinLogin && res.assetData.assetType === CONSTANTS.KIOSK_TYPE.SUPER_KIOSK_TERMINAL) {
      sessionStorage.setItem('terminalType', res.assetData.assetType);
      sessionStorage.setItem('sk_token', res.token);
      sessionStorage.setItem('sk_lPin', res.assetData.lPin);
      sessionStorage.setItem('superkTerminalId', res.assetData._id);
    }

    if (res.assetData?.store && res.assetData?.name) {
      const storeId = res.assetData?.store;
      const storeName = res.assetData?.name;
      sessionStorage.setItem('location', storeId);
      sessionStorage.setItem('branchName', storeName);
    }
    if(res.assetData['isSalesReportDownload'])
    {
      sessionStorage.setItem('salesReportPasscode', res.assetData['salesReportPasscode']);
    }else
    {
      sessionStorage.removeItem('salesReportPasscode');
    }

    return {
      ...state
    };
  }),

  on(getAccountDataSuccess, (state, { res, lPin }) => {
    const logoData = res?.images?.logo?.url;
    sessionStorage.setItem('logoData', logoData);
    sessionStorage.setItem('accountName', res.name);
    const updatedAsset = { ...state.assetData };
    updatedAsset[lPin] = { ...updatedAsset[lPin], accountName: res.name, logoData };

    return {
      ...state,
      assetData: updatedAsset,
      loginLoader: false,
      logoUrl: res?.images?.logo?.url
    };
  }),

  on(login, (state) => {
    return {
      ...state,
      loginLoader: true
    };
  }),

  on(wrongCredential, (state) => {
    return {
      ...state,
      loginFail: true,
      loginLoader: false
    };
  }),


  on(cachedLogin, (state, { loginData }) => {
    sessionStorage.removeItem('token');
    sessionStorage.setItem(`${loginData?.accountId}token`, loginData.token);
    sessionStorage.setItem('lPin', loginData.lPin);
    sessionStorage.setItem('kTerminalId', loginData._id);

    if (loginData?.store && loginData?.name) {
      const storeId = loginData?.store;
      const storeName = loginData?.name;
      sessionStorage.setItem('location', storeId);
      sessionStorage.setItem('branchName', storeName);
    }
    sessionStorage.setItem('logoData', loginData.logoData);
    sessionStorage.setItem('accountName', loginData.accountName);

    return {
      ...state,
    };
  }),
  on(updateWallpaperFromSocket, (state, { data }) => {
    const pin = sessionStorage.getItem('lPin');
    const updatedAsset = { ...state.assetData, [pin]: { ...state.assetData[pin], wallpaper: data?.data?.assetData?.wallpaper } };
    return {
      ...state,
      assetData: updatedAsset,
    };
  }),
);
