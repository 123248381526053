import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToastModule} from 'primeng/toast';
import {DialogModule} from 'primeng/dialog';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonModule} from 'primeng/button';
import {ToastPopup} from 'src/app/models/kioskUiSettings.model';

@Component({
  selector: 'app-printer-toast',
  standalone: true,
  imports: [CommonModule, ToastModule, DialogModule, TranslateModule, ButtonModule],
  templateUrl: './printer-toast.component.html',
  styleUrls: ['./printer-toast.component.scss']
})

export class PrinterToastComponent {
  @Input() toastData: ToastPopup;
  @Output() onConfirmation = new EventEmitter<string>();

  restartApp() {
    this.onConfirmation.emit('confirm');
  }

}

