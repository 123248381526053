import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = sessionStorage.getItem(`${sessionStorage.getItem('accountId')}_token`);
    let request = req;
    const protocol = request?.url.slice(0, 5);
    if (authToken && protocol === 'https') {
      request = req.clone({
        headers: req.headers.set('Authorization', authToken)

      });

    }
    if (req.method === 'POST' || req.method === 'PUT') {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json')
      });
    }
    return next.handle(request);

  }
}
